import React from 'react';
import Image from "../assets/hero.jpg";

const Hero = () => {
  return (
    <div className="w-full h-[500px] md:rounded-md">
        <img 
            className="md:object-fill object-cover w-full h-full md:rounded-md"
            src={Image} 
            alt="about" />
    </div>
  )
}

export default Hero