import React, {createContext, useContext, useState} from 'react'

const StateContext = createContext()


export const ContextProvider = ({children}) => {
    const [menu, setMenu] = useState(1)
    const [selectLibrary, setSelectLibrary] = useState(1)


    return (
        <StateContext.Provider value={{menu, setMenu, selectLibrary, setSelectLibrary}}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)