import React from 'react';
import { useTranslation } from "react-i18next";
import EnqueteButton from '../../utils/EnqueteButton';

import NextEvent from './components/NextEvent';
import Pagination from './components/Pagination';

export default function Index() {
  const { t } = useTranslation();

  return (
    <div className='relative'>
      <EnqueteButton />
      <div className="pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8">
        <div className="flex flex-col mb-10 md:flex-row justify-between md:px-0 px-4">
          <h2 className="max-w-md font-sans text-4xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
            {t("next_event")}
          </h2>
        </div>
        <NextEvent />
        <div className="flex flex-col mb-10 mt-16 md:flex-row justify-between md:px-0 px-4">
          <h2 className="max-w-md font-sans text-4xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
            {t("last_events")}
          </h2>
        </div>
        <Pagination />
      </div>
    </div>
  );
}
