import React from 'react'


const Speakers = ({speakers}) => {
  return (
    <div className="px-4 py-16 w-full md:px-8 lg:px-20 lg:py-10 bg-gray-100">
      <div className="w-full pb-16">
        <h1 className="text-edenmedvert text-center text-3xl font-semibold uppercase mt-6">Conférenciers</h1>
      </div>
      <div className="flex flex-wrap justify-between xl:px-20">
        {speakers?.map((item)=>(
            <div key={item._id} className="overflow-hidden w-[230px] mx-auto mb-6">
                <div className="aspect-1">
                    <img
                    src={item.avatar}
                    className="object-cover w-full aspect-1"
                    alt={item.name}
                    referrerPolicy="no-referrer"
                    />
                </div>
                <div className="py-2">
                <p className="mb-1 text-lg font-semibold tracking-wide uppercase">
                    {item.name}
                </p>
                <p className=" text-gray-700">
                    {item.occupation}
                </p>
                </div>
            </div>
        ))}
        
      </div>
    </div>
  )
}

export default Speakers