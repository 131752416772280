import React from 'react'
import EnqueteButton from '../../utils/EnqueteButton';

import Documents from './components/Documents'
import Medias from './components/Medias'
import { useTranslation } from "react-i18next";
import banner from "../../assets/images/library-banner.jpg";

import { useStateContext } from "../../contexts/ContextProvider"
import Photos from './components/Photos';


const Index = () => {
  const {selectLibrary, setSelectLibrary} = useStateContext();
  const { t } = useTranslation();
  

  return (
    <div className='relative'>
      <EnqueteButton />
      <div className="bg-gray-100 pb-16">
        <div className="">
          <div className="w-full h-72">
            <img 
              className="object-cover w-full h-full" 
              src={banner}
              alt="hero" />
          </div>
          <div className="px-4 mt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8">
            <h2 className="max-w-md font-sans text-4xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
              {t("library_title")}
            </h2>
          </div>
          <div className="px-4 mt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8">
            <p className="font-sans text-lg font-medium text-black max-w-4xl">
            {t("library_description")}
            </p>
          </div>
          <div className="px-4 mt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8">
            <div className="mx-auto shadow-xl">
              <ul className="flex gap-2 md:mt-0 mt-4 items-center">
                <li className= {`w-36 px-2 py-2 ${selectLibrary===1 ? "border-b-2 border-edenmedciel" :"border-0"} font-medium text-center cursor-pointer`} onClick={()=>setSelectLibrary(1)}>
                {t("library_document")}
                </li>
                <li className= {`w-36 px-2 py-2 ${selectLibrary===2 ? "border-b-2 border-edenmedciel" :"border-0"} font-medium text-center cursor-pointer`} onClick={()=>setSelectLibrary(2)}>
                {t("library_media")}
                </li>
                <li className= {`w-36 px-2 py-2 ${selectLibrary===3 ? "border-b-2 border-edenmedciel" :"border-0"} font-medium text-center cursor-pointer`} onClick={()=>setSelectLibrary(3)}>
                {t("library_photo")}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="px-4 mt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8">
          {selectLibrary===1 && <Documents />}
          {selectLibrary===2 && <Medias />}
          {selectLibrary===3 && <Photos />}
        </div>
      </div>
    </div>
  )
}

export default Index