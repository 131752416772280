import React from 'react';
import EnqueteButton from '../../utils/EnqueteButton';

import Slider from './components/Slider';
import Statistic from './components/Statistic';
import Feature from './components/Feature';
import Events from './components/Events';
import About from './components/About';
import Faq from './components/Faq';
import Testimonial from './components/Testimonial';
import Pupup from './components/Pupup';
import Article from './components/Article';
import Partenareat from './components/Partenareat';

export default function Home() {
  return (
    <div className='relative'>
      <EnqueteButton />
      <div>
        <Pupup />
        <Slider />
        <Statistic />
        <Feature />
        <Article />
        <Events />
        <About />
        <Faq />
        <Testimonial />
        <Partenareat />
      </div>
    </div>
  );
}
