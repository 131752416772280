import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getTestimonials } from '../actions';
import Loading from '../../../components/Loading';

const Testimonial = (props) => {
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng');

  useEffect(() => {
    props.getTestimonials(language);
  }, [language]);

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 md:flex-row md:mb-10 justify-between">
        <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
          {t('home_testimonial_title')}
        </h2>
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="w-full bg-gray-100"
      >
        {props.loadingTestimonials 
          ?
            <div className="space-y-4">
              <Loading height={"h-[400px]"} />
            </div>
          :
            <>
              {props.testimonials.map((item) => (
                <SwiperSlide key={item._id}>
                  <div className="grid grid-cols-5 gap-4 md:px-12 px-4 lg:py-32 md:py-24 py-4">
                    <div className="flex flex-col items-center col-span-5 md:col-span-2">
                      <img
                        className="object-cover w-72 h-72 mb-2 rounded-md shadow"
                        src={item.avatarUrl}
                        alt={item.name}
                        referrerPolicy="no-referrer"
                      />
                      <div className="flex flex-col items-center">
                        <p className="text-lg font-bold">{item.name}</p>
                        <p className="text-sm text-gray-800">{item.position}</p>
                      </div>
                    </div>
                    <div className="w-full h-full col-span-5 md:col-span-3 flex flex-col gap-4">
                      <div className="flex items-start justify-start">
                        <FaQuoteLeft className="h-10 w-10 text-blue-600" />
                      </div>
                      <div className="lg:px-12 md:px-8 px-0 font-medium flex lg:flex-row flex-col lg:gap-8 gap-2 h-1/2">
                        <p className="flex items-center text-center text-lg lg:px-0 px-8">
                          {item.description}
                        </p>
                      </div>
                      <div className="flex items-end justify-end">
                        <FaQuoteRight className="h-10 w-10 text-blue-600" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </>}
      </Swiper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  testimonials: state.home.testimonials,
  loadingTestimonials: state.home.loadingTestimonials,
});

const mapActionsToProps = {
  getTestimonials,
};
export default connect(mapStateToProps, mapActionsToProps)(Testimonial);
