import React, { useState } from 'react';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getAllEvents } from "../../events/actions";
import { getAllBlogs } from "../../articles/actions";
import { getVisitors } from '../actions';

import { useEffect } from "react";


const Statistic = (props) => {
  const [counterOn, setCounterOn] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    props.getAllEvents() 
    props.getAllBlogs() 
    props.getVisitors() 
  }, [])

  return (
    <div className="py-6 relative max-w-3xl px-6 lg:px-8  z-10 md:-mt-16 mx-auto rounded-md bg-white shadow-2xl">
      <ScrollTrigger onEnter={()=>setCounterOn(true)}>
      {counterOn && 
        <div className="sm:grid sm:grid-cols-3 sm:row-gap-4 md:row-gap-8 flex justify-between">
          <div className="text-center md:border-r">
            <h6 className="text-4xl font-bold lg:text-5xl text-edenmedbleu"><CountUp start={0} end={props.blogs.totalBlogs} duration={4} delay={0}/></h6>
            <p className="text-xs md:text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              {t("home_stat_telechargements")}
            </p>
          </div>
          <div className="text-center md:border-r">
            <h6 className="text-4xl font-bold lg:text-5xl text-edenmedbleu"><CountUp start={0} end={props.events.totalEvents} duration={4} delay={0}/></h6>
            <p className="text-xs md:text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
            {t("home_stat_events")}
            </p>
          </div>
          <div className="text-center">
            <h6 className="text-4xl font-bold lg:text-5xl text-edenmedbleu"><CountUp start={0} end={props.visitors.length} duration={4} delay={0}/></h6>
            <p className="text-xs md:text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
            {t("home_stat_visiteurs")}
            </p>
          </div>
        </div>
        }
      </ScrollTrigger>
    </div>
  );
}

const mapStateToProps = (state) => ({
  events: state.events,
  blogs: state.blogs,
  visitors: state.home.visitors,
});

const mapActionsToProps = {
  getAllEvents, getAllBlogs, getVisitors
};
export default connect(mapStateToProps, mapActionsToProps)(Statistic);
