import { LOADING_CONTACTUS, SUCCESS_CONTACTUS, FAIL_CONTACTUS } from "./types";

const initialState = {
  loading: false,
  success: false,
  fail: false,
  alertMessage: "",
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOADING_CONTACTUS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_CONTACTUS:
      return {
        ...state,
        success: true,
        fail: false,
        loading: false,
        alertMessage: payload,
      };

    case FAIL_CONTACTUS:
      return {
        ...state,
        fail: true,
        success: false,
        loading: false,
        alertMessage: payload,
      };

    default:
      return state;
  }
}
