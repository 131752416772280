import { 
          LOADING_EVENT_DETAILS, 
          SUCCESS_GET_EVENT_DETAILS, 
          SET_ERROR_EVENT_DETAILS, 
          LOADING_PARTICIPANTS,
          SUCCESS_GET_PARTICIPANTS,
          SUCCESS_ADD_PARTICIPANTS,
          SET_ERROR_PARTICIPANTS,
       } from "./types";
import axios from "axios";

export const getEventDetails = (eventId) => (dispatch) => {
    dispatch({ type: LOADING_EVENT_DETAILS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/events/event/${eventId}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_EVENT_DETAILS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_EVENT_DETAILS,
          payload: [],
        });
      });
  };


export const getParticipants = (eventId) => (dispatch) => {
  dispatch({ type: LOADING_PARTICIPANTS });
  axios
    .get(`${process.env.REACT_APP_API_URL}/participants/${eventId}`)
    .then((res) => {
      dispatch({
        type: SUCCESS_GET_PARTICIPANTS,
        payload: res.data,
      });
    })
    .catch((err) => {
      
    });
};

export const sendParticipation = (id, participantData) => (dispatch) => {
  dispatch({ type: LOADING_PARTICIPANTS });
  axios
    .post(`${process.env.REACT_APP_API_URL}/participants`, participantData)
    .then((res) => {
      dispatch(getEventDetails(id));
      dispatch({
        type: SUCCESS_ADD_PARTICIPANTS,
        payload: "Votre participation a été envoyé avec succès",
      });
    })
    .catch((err) =>
      {
        dispatch({
          type: SET_ERROR_PARTICIPANTS,
          payload: 
          err.response.data.error === "You are already signed in for this event" 
                                      ? "Vous avez déjà inscrit à cet évènement" 
                                      : "Une erreur s'est produite veuillez réessayer",
        })
      }
      
    );
};

export const confirmParticipants = (activationCode) => (dispatch) => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/participants/participant/confirmation/${activationCode}`)
    .then((res) => {
      
    })
    .catch((err) => {
      
    });
};
