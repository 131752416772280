import React, { useState } from 'react'

const Main = () => {
  const [showPresentationReadMore, setShowPresentationReadMore] = useState(false)
  const [showVisionReadMore, setShowVisionReadMore] = useState(false)
  return (
    <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl mt-24">
      <div className="mb-10">
        <h2 className="font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none">
          Présentation du projet
        </h2>
      </div>
      <div className={`${showPresentationReadMore ? "mb-10" : "mb-16"}`}>
        <p className="text-base text-justify">
          &emsp;&emsp;&emsp;La question de l’éducation environnementale doit être actualisée sans cesse au regard de
         l’évolution et la complexification de la crise écologique sur tous les plans : local, régional ou global. 
         Bien que cette éducation environnementale est présente dans les programmes scolaires, elle demeure no 
         actualisée ni dans le contenu ni dans les approches pédagogiques. Parallèlement, elle se situe dans 
         l’éducation informelle périscolaire par des expériences comme les clubs de l’environnement 
         où du développement durable. <span className={`text-edenmedciel cursor-pointer ${showPresentationReadMore && "hidden"}`} onClick={()=>setShowPresentationReadMore(true)}>Lire plus...</span>
        </p>
      </div>
      <div className={`mb-10 ${!showPresentationReadMore && "hidden"}`}>
        <p className="text-base text-justify">
          &emsp;&emsp;&emsp;Ce sont donc des activités ponctuelles initiées par des enseignants ou chefs d’établissements impliquant 
          une partie des élèves. Nous sommes donc en présence d’une éducation environnementale formellement non-actualisée et une éducation 
          informelle inégalement pratiquée dans des établissements qui s’y prêtent de par les moyens ou les ressources disponibles. De surcroît, 
          ces pratiques éducatives informelle n’ont pas permis réellement de changer significativement les comportements des citoyens dans le 
          sens souhaité de manière à les rendre plus conscients des enjeux environnementaux et d’adopter une attitude raisonnable que ça soit 
          au niveau des modes de production ou de consommation.
        </p>
      </div>
      <div className={`mb-10 ${!showPresentationReadMore && "hidden"}`}>
        <p className="text-base text-justify">
          &emsp;&emsp;&emsp;Stratégiquement il faudrait Introduire l’éducation environnementale dans le programme scolaire
           de façon entièrement transversale. C’est l’objet principal du projet EdEn-MED : Education Environnementale pour 
           une Méditerranée Durable. Son objectif spécifique est le développement d’une mobilité des acteurs de l’éducation 
           et de la protection de l’environnement : responsables, inspecteurs, enseignants des écoles et collèges ainsi que 
           des élèves dans la zone du programme pour renforcer la coopération en faveur d’une éducation environnementale innovante 
           et pérenne. Afin de réaliser cet objectif, les outputs à concevoir consistent à la mise en place d’une plate-forme 
           d’échange et d’un réseau de mobilité entre la Tunisie et la Sicile ainsi que l’élaboration d’un manuel pédagogique 
           pour une éducation environnementale.
        </p>
      </div>
      <div className={`mb-16 ${!showPresentationReadMore && "hidden"}`}>
        <p className="text-base text-justify">
          &emsp;&emsp;&emsp;Le projet EdEn-MED commence par la réalisation d’une étude des perceptions environnementales 
          en milieu scolaire, en Tunisie et en Sicile, partant d’un état des lieux des expériences passées et la 
          capitalisation des meilleures pratiques en matière d’Education Environnementale. Ensuite, on va engager 
          une campagne de sensibilisation dans le milieu scolaire (responsables, élèves, enseignants et inspecteurs) 
          avant de passer au renforcement de leurs capacités par un cycle de formation adapté. Ensuite, il est prévu 
          d’élaborer un manuel de l’éducation environnementale qui serait un support pour une réforme, partielle 
          ou globale, du système scolaire. Enfin, il est envisagé la mise en place d’une plate-forme virtuelle dont 
          la fonction est de permettre des échanges entre les acteurs de l’éducation environnementale dans la zone 
          du programme. Cela ne peut se faire sans la signature des accords et conventions entre les parties 
          prenantes tunisiennes et siciliennes.
        </p>
      </div>
      <div className="mb-10">
        <h2 className="font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none">
          La vision stratégique
        </h2>
      </div>
      <div className={`${showVisionReadMore ? "mb-10" : "mb-16"}`}>
        <p className="text-base text-justify">
          &emsp;&emsp;&emsp;Le projet stratégique EdEn-MED vise au bout du processus à injecter la pédagogie de l’éducation
           environnementale dans le système scolaire en Tunisie et en Sicile. Il associe des structures publiques dans la zone
            du programme Italie-Tunisie afin qu’elles puissent mobiliser les principaux acteurs de 
            l’éducation. <span className={`text-edenmedciel cursor-pointer ${showVisionReadMore && "hidden"}`} onClick={()=>setShowVisionReadMore(true)}>Lire plus...</span>
        </p>
      </div>
      <div className={`mb-10 ${!showVisionReadMore && "hidden"}`}>
        <p className="text-base text-justify">
          &emsp;&emsp;&emsp;Le public cible se compose des enseignants du primaire, des enseignants du collège et du secondaire, 
          des enseignants-chercheurs dans le domaine des sciences de l’éducation ainsi que les inspecteurs et les hauts responsables
           de l’éducation publique. L’implication des parties prenantes dans l’implémentation du projet dès son démarrage capable de 
           garantir la pérennisation de l’esprit du projet au-delà de ses termes dans le temps. 
        </p>
      </div>
      <div className={`mb-16 ${!showVisionReadMore && "hidden"}`}>
        <p className="text-base text-justify">
          &emsp;&emsp;&emsp;Les principaux outputs du projet EdEn-MED consistent à la conception, à partir d’expérience pilote, 
          une pédagogie éducative (manuel pédagogique) sur les enjeux environnementaux adaptée à chaque niveau scolaire. 
          Ce sont les Ministères et départements publics de l’éducation qui seront les bénéficiaires de ces outputs dans 
          la mesure où ils appliqueront cette pédagogie dans les établissements éducatifs (écoles, collèges et lycées) par 
          le changement des programmes scolaires progressivement et interactivement avec les inspecteurs et les enseignants. 
          C’est une nouvelle politique publique dans l’éducation qui sera élaborée pour intégrer de manière substantielle et structurelle
           l’éducation environnementale transversalement dans les programmes scolaires. 
        </p>
      </div>
      <div className="grid gap-8 md:grid-cols-2 sm:mx-auto">
        <div className="px-3 py-8 bg-gray-200 rounded-md shadow-lg">
          <div className="rounded-md py-3 px-1 flex flex-col gap-2 h-[400px]">
            <h1 className="pb-8 mb-8 text-center text-2xl font-bold border-b-2 border-edenmedvert">EdenMED</h1>
            <p className="text-center text-base">Education Environnementale pour une Mediterranée durable</p>
            <h1 className="text-center text-xl font-semibold">Un projet stratégique</h1>
            <h1 className="text-center text-lg font-medium">Objectifs thématiques N°2 :</h1>
            <p className="text-center text-base">Soutien à l'éducation, à la recherche, au développement technologique et à l'innovation</p>
            <h1 className="text-center text-lg font-medium">Priorité N°2.3 :</h1>
            <p className="text-center text-base">Appui à la coopération locale dans le domaine de l'éducation</p>
          </div>
        </div>
        <div className="px-3 py-8 bg-gray-200 rounded-md shadow-lg">
          <div className="rounded-md py-3 px-1 flex flex-col gap-2 h-[400px]">
            <h1 className="text-center text-2xl font-bold uppercase mb-8 border-b-2 border-edenmedvert pb-8">Objectif global</h1>
            <p className="text-center text-base">Injecter la pédagogie de l'éducation environnementale dans le système scolaire en Tunisie et en Scilie.</p>
            <p className="text-center text-base">Soutien à l'éducation, à la recherche, au développement technologique et à l'innovation.</p>
            <p className="text-center text-base">La réalisation principale consiste en la mobilité renforcée des étudiants, des chercheurs et des enseignants dans la zone du programme.</p>
          </div>
        </div>
        <div className="px-3 py-8 bg-gray-200 rounded-md shadow-lg">
          <div className="rounded-md py-3 px-1 flex flex-col gap-2 h-[400px]">
            <h1 className="text-center text-2xl font-bold uppercase mb-8 border-b-2 border-edenmedvert pb-8">objectif spécifique</h1>
            <p className="text-center text-base">Développement d'une mobilité des acteurs de l'éducation et de la protection de l'environnement:</p>
            <p className="text-center text-sm">responsables, inspecteurs, enseignants des écoles et des collèges ainsi que des élèves dans la zone du programme</p>
            <p className="text-center text-base">pour renforcer la coopération en faveur d'une éducation environnementale innovante et prénne.</p>
          </div>
        </div>
        <div className="px-3 py-8 bg-gray-200 rounded-md shadow-lg">
          <div className="rounded-md py-3 px-1 flex flex-col gap-2 lg:h-[400px] h-[500px]">
            <h1 className="text-center text-2xl font-bold uppercase mb-8 border-b-2 border-edenmedvert pb-8">ACTIONS A ENTREPRENDRE</h1>
            <p className="text-center text-base">1-	Échange de bonnes pratiques entre les établissements d'enseignement, les universités, les autorités locales et régionales et tout autre acteur pertinent pour le développement visant à établir des modalités de coopération entre les deux pays dans le domaine de la formation et de l'éducation.</p>
            <p className="text-center text-base">2-	Soutien à la mobilité entre les deux pays des étudiants, des enseignants et autre personnel non enseignant dans les universités et les écoles, collèges et lycées, entre autre, dans les domaines d'intégration des TIC, de l'enseignement technique et des sciences humaines.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main