import { Routes, Route, HashRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './redux/store';
import ScrollToTop from './utils/ScrollToTop';

import Layout from './components/Layout.js';
import Home from './pages/home/Index.js';
import Contact from './pages/contact/Index.js';
import Events from './pages/events/Index.js';
import Articles from './pages/articles/Index.js';
import About from './pages/about/Index.js';
import Faq from './pages/faq/Index.js';
import EventDetails from './pages/eventDetails/Index';
import ArticleDetails from './pages/articleDetails/Index';
import Bibliotheque from './pages/library/Index';
import EmailConfirmation from './pages/emailConfirmation/Index';
import PageNotFound from './components/PageNotFound';
import Enquete from './pages/survey/Index';

function App() {
  
  
  return (
    <Provider store={store}>
      <HashRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route exact index element={<Home />} />
              <Route path="contact" element={<Contact />} />
              <Route path="events" element={<Events />} />
              <Route path="events/:id" element={<EventDetails />} />
              <Route path="articles" element={<Articles />} />
              <Route path="articles/:id" element={<ArticleDetails />} />
              <Route path="apropos" element={<About />} />
              <Route path="faq" element={<Faq />} />
              <Route path="bibliotheque" element={<Bibliotheque />} />
              <Route path="enquete/:id" element={<Enquete />} />
              <Route
                path="confirmation/:activationCode"
                element={<EmailConfirmation />}
              />
            </Route>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ScrollToTop>
      </HashRouter>
    </Provider>
  );
}

export default App;
