import logo from '../assets/images/logo-footer.png';
import { Link } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getLastEvents } from '../pages/events/actions';
import { useEffect } from 'react';

const Footer = (props) => {
  const { setMenu, setSelectLibrary } = useStateContext();
  const language = localStorage.getItem('i18nextLng');

  const { t } = useTranslation();

  useEffect(() => {
    props.getLastEvents(language);
  }, [language]);

  return (
    <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8">
      <div className="grid gap-16 row-gap-10 mb-8 lg:grid-cols-6">
        <div className="md:max-w-md lg:col-span-2">
          <img className="h-48 w-auto" src={logo} alt="Eden-Med" />
          <div className="mt-4 lg:max-w-sm">
            {/* <p className="text-sm text-gray-800">{t('footer_description')}</p> */}
            <p className="text-sm text-gray-800">
              {t('footer_sub_description')}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-4 md:m-16">
          <div>
            <p className="font-semibold tracking-wide text-gray-800">
              {t('footer_link')}
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link
                  to="/"
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_Accueil')}
                </Link>
              </li>
              <li>
                <Link
                  to="/apropos"
                  onClick={() => setMenu(1)}
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_Apropos')}
                </Link>
              </li>
              <li>
                <Link
                  to="/events"
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_events')}
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_Contact')}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-gray-800">
              {t('footer_events')}
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link
                  to={`/events/${props?.events?.lastEvents[0]?._id}`}
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_next_event')}
                </Link>
              </li>
              <li>
                <Link
                  to="events"
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_last_event')}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-gray-800">Blog</p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link
                  to="articles"
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_actualite')}
                </Link>
              </li>
              <li>
                <Link
                  to="/faq"
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_Faq')}
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <p className="font-semibold tracking-wide text-gray-800">
              {t('footer_library')}
            </p>
            <ul className="mt-2 space-y-2">
              <li>
                <Link
                  to="bibliotheque"
                  onClick={() => setSelectLibrary(1)}
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_Documents')}
                </Link>
              </li>
              <li>
                <Link
                  to="bibliotheque"
                  onClick={() => setSelectLibrary(2)}
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_Vidéos')}
                </Link>
              </li>
              <li>
                <Link
                  to="bibliotheque"
                  onClick={() => setSelectLibrary(3)}
                  className="text-gray-600 hover:underline hover:text-edenmedvert transition-colors duration-300 hover:text-deep-purple-accent-400"
                >
                  {t('footer_Photos')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col sm:justify-between items-center py-2 border-t">
        <a
          href="https://fr-fr.facebook.com/bspartage/"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: 'none',
            color: 'transparent',
          }}
        >
          <p className="text-sm text-gray-900 pl-1">
            © Copyright 2023 BS Partage. {t('footer_right_reserved')}.
          </p>
        </a>
        <div className="flex items-center mt-4 space-x-4 sm:mt-0 pl-1 sm:pl-0 sm:pr-1">
          <a
            href="https://www.youtube.com/@edenmedEtalieTunisie"
            target="_blank"
            rel="noreferrer"
            className="text-gray-900 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-6"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                clipRule="evenodd"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/edenMed.tn.it/"
            target="_blank"
            rel="noreferrer"
            className="text-gray-900 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
              <circle cx="15" cy="15" r="4" />
              <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/edenMed.tn.it"
            target="_blank"
            rel="noreferrer"
            className="text-gray-900 transition-colors duration-300 hover:text-deep-purple-accent-400"
          >
            <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
              <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  events: state.events,
});
const mapActionsToProps = {
  getLastEvents,
};

export default connect(mapStateToProps, mapActionsToProps)(Footer);
