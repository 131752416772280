import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import notificationSound from '../assets/notification.mp3';
import { connect } from 'react-redux';
import { countVisitors, getPupup } from '../actions';

const useAudio = (url) => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    const promise = audio.play();
    if (promise !== undefined) {
      promise
        .then(() => {
          playing ? audio.play() : audio.pause();
        })
        .catch((error) => {
          audio.muted = false;
          audio.play();
        });
    }
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const Pupup = (props) => {
  let [isOpen, setIsOpen] = useState(false);
  const [playing, toggle] = useAudio(notificationSound);
  const language = localStorage.getItem('i18nextLng');

  useEffect(() => {
    props.getPupup(language);
    setTimeout(() => {
      let modalPopUp = localStorage.getItem('modalPopUp');
      let isLastPopUpFiredAnHourAgo =
        new Date().getHours() - parseInt(modalPopUp) > 1;
      if ((!modalPopUp || isLastPopUpFiredAnHourAgo) && !props.loadingPupup) {
        setIsOpen(true);
        toggle();
        localStorage.setItem('modalPopUp', new Date().getHours());
      }
    }, 3000);
  }, [language]);

  function closeModal() {
    setIsOpen(false);
    props.countVisitors();
  }

  return (
    <div>
      {props.pupup?.imageUrl && (
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed bottom-4 right-8 z-40"
            onClose={closeModal}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="-translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="-translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative max-w-sm rounded-md  w-full mx-auto shadow-xl flex flex-col overflow-y-auto">
                <button
                  onClick={closeModal}
                  className="absolute top-6 right-6 flex items-center justify-center w-10 h-10 mx-auto text-gray-600 duration-300 transform border border-gray-600 rounded-full hover:text-teal-accent-400 hover:border-teal-accent-400 hover:shadow hover:scale-110 bg-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 float-right relative "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                {!props.loadingPupup && (
                  <img
                    src={`${process.env.REACT_APP_API_URL}${props.pupup.imageUrl}`}
                    alt="EdenMed"
                  />
                )}
                {!props.loadingPupup && props.pupup.label && (
                  <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 w-full">
                    <p className="text-white text-center bg-black/50 p-4 rounded-md w-full">
                      {props.pupup.label}
                    </p>
                  </div>
                )}
              </div>
            </Transition.Child>
          </Dialog>
        </Transition.Root>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  visitors: state.home.visitors,
  loadingVisitors: state.home.loadingVisitors,
  loadingPupup: state.home.loadingPupup,
  pupup: state.home.pupup,
});

const mapActionsToProps = {
  countVisitors,
  getPupup,
};
export default connect(mapStateToProps, mapActionsToProps)(Pupup);
