import React from 'react';
import { MdOutlineWatchLater } from "react-icons/md";


const Schedule = ({schedule}) => {
  return (
    <div className="px-4 py-16 mx-auto md:w-5/6 lg:w-3/4 md:px-8 lg:py-20">
      <div className="w-full pb-16">
        <h1 className="text-edenmedvert text-center text-3xl font-semibold uppercase">Planning</h1>
      </div>
      <div className="px-1">
        {schedule?.map((item)=>(
            <div key={item._id} className="flex sm:gap-2 gap-1 mb-2">
                <div className="flex justify-center bg-edenmedciel sm:w-1/4 w-[20%]">
                    <div className="flex flex-col items-center my-auto text-center text-white p-4">
                        <div className="flex items-center justify-center sm:w-12 sm:h-12 w-10 h-10mx-auto">
                            <MdOutlineWatchLater className="sm:w-10 sm:h-10 w-8 h-8" />
                        </div>
                        <h6 className="sm:text-3xl text-xl font-normal">
                            {item.time}
                        </h6>
                    </div>
                </div>
                <div className="sm:w-3/4 w-[80%] bg-gray-100 sm:p-4 p-2">
                    <h1 className="text-edenmedciel text-xl font-medium mb-2">{item.title}</h1>
                    <p className="sm:text-base text-sm">{item.description}</p>
                </div>
            </div>
        ))}
      </div>
    </div>
  )
}

export default Schedule