import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { connect } from 'react-redux';
import { getPhotos } from "../actions";
import { useTranslation } from "react-i18next";
import Loading from '../../../components/Loading';


const Photos = (props) => {
    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const { t } = useTranslation();
    const language = localStorage.getItem("i18nextLng")
    const months = language === "fr" ? ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"] : ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const description_max_length = 100;
    const title_max_length = 40;

    useEffect(() => {
        props.getPhotos(language)
    }, [language])

    const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }
  return (
    <div className="relative">
        {open && 
            <div className="fixed inset-0 w-screen h-screen bg-black/80 z-20">
                <p 
                    className="absolute lg:right-5 right-2 top-2  p-1 flex justify-center items-center w-8 h-8 cursor-pointer rounded-full z-30"
                    onClick={()=>setOpen(false)}>
                        <AiOutlineCloseCircle className="w-8 h-8 text-white hover:text-gray-900"/>
                    </p>
                <div className="relative inset-x-0 top-12 m-auto w-fit h-fit p-4">
                    <img 
                        className="w-full sm:h-[450px]"
                        src={url}
                        alt={title}
                        referrerPolicy="no-referrer"/>
                    <h1
                    className="inline-block my-3 text-xl font-bold text-white"
                    >
                        {title}
                    </h1>
                    <p 
                        className={`text-white  max-w-xl ${isArabic(description) && "text-justify"}`}
                        dir={isArabic(description) ? "rtl" : undefined}
                    >
                        {description}
                    </p>
                </div>
            </div>
        }
        {props.photos.loading 
            ?
                <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-2 max-w-full">
                    <Loading height={"h-[400px]"} />
                    <Loading height={"h-[400px]"} />
                    <Loading height={"h-[400px]"} />
                    <Loading height={"h-[400px]"} />
                </div>
            :
                <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-2 max-w-full">
                    {props.photos.data.map((item)=>(
                        <div key={item._id} className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm">
                            <img
                                src={item.imageUrl}
                                onClick={()=>{setUrl(item.imageUrl);setTitle(item.title);setDescription(item.description);setOpen(true) }}
                                className="object-cover w-full h-48 cursor-pointer"
                                alt=""
                            />
                            <div className="p-4">
                                <h1
                                className={`inline-block mb-2 h-14 overflow-hidden text-xl font-bold cursor-pointer hover:underline ${isArabic(item.title) && "text-justify"}`}
                                onClick={()=>{setUrl(item.imageUrl);setTitle(item.title);setDescription(item.description);setOpen(true) }}
                                dir={isArabic(item.title) ? "rtl" : undefined}
                                >
                                    {item.title.length > title_max_length ? <span>{item.title.slice(0,title_max_length)}...</span> : <span>{item.title}</span>}
                                </h1>
                                <div className="relative overflow-hidden text-sm text-gray-500 mb-2">
                                    <span>{t("library_added_at")} {new Date(item.date).getDate()} {months[new Date(item.date).getMonth()]} {new Date(item.date).getFullYear()}</span>
                                </div>
                                <p 
                                    className={`text-gray-700 h-24 overflow-hidden ${isArabic(item.description) && "text-justify"}`}
                                    dir={isArabic(item.description) ? "rtl" : undefined}>
                                    {item.description.length > description_max_length ? <span>{item.description.slice(0,description_max_length)}...</span> : <span>{item.description}</span>}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            }
    </div>
  )
}

const mapStateToProps = (state) => ({
    photos: state.photos,
  });
  
  const mapActionsToProps = {
    getPhotos,
  };
  export default connect(mapStateToProps, mapActionsToProps)(Photos);