import React from 'react';

import logo1 from "../assets/new-anpe-logo.png";
import logo2 from "../assets/new-arpa-logo.png";
import logo3 from "../assets/new-ciffip-logo.png";
import logo4 from "../assets/new-agrigento-logo.png";
import logo5 from "../assets/new-orsa-logo.png";
import logo6 from "../assets/new-cnr-logo.png";

const data = [
  {
      id: 1,
      title: "Anpe",
      image: logo1,
      link: "http://www.environnement.gov.tn/index.php/fr/agence-nationale-de-protection-de-l-environnement",
  },
  {
    id: 2,
    title: "Arpa",
    image: logo2,
    link: "https://www.arpa.sicilia.it/",
  },
  {
      id: 3,
      title: "Ciffip",
      image: logo3,
      link: "http://www.ciffip.tn/fr/",
  },
  {
      id: 4,
      title: "agrigento",
      image: logo4,
      link: "https://www.unipa.it/amministrazione/politerritoriali/poloterritorialeagrigento/",
  },
  {
    id: 5,
    title: "Orsa",
    image: logo5,
    link: "https://orsaconsulting.it/",
  },
  {
    id: 6,
    title: "Cnr",
    image: logo6,
    link: "https://www.cnr.it/it/istituto/124/articolazione-territoriale/istituto-per-lo-studio-degli-impatti-antropici-e-sostenibilita-in-ambiente-marino-ias",
  },
]


const Partenaires = () => {

  return (
    <div className="mt-24">
      <div className="mb-16">
        <h2 className="font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none">
          Partenaires
        </h2>
      </div>
        <div className="grid gap-8 lg:grid-cols-3 md:grid-cols-2 max-w-full">
            {data.map((item)=>(
                <div key={item.id} className="flex items-center justify-center h-full w-full">
                    <a 
                        href={item.link}
                        className="flex items-center justify-center h-full w-full"
                        target="_blank"
                        rel="noreferrer">
                        <img src={item.image} alt={item.title} className="cursor-pointer h-full w-full hover:h-[99%] hover:w-[99%]"/>
                    </a>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Partenaires