import { LOADING_BLOGS, SUCCESS_GET_BLOGS, SET_ERROR_BLOGS, SUCCESS_GET_MOST_SEEN_BLOGS, SUCCESS_GET_ALL_BLOGS } from "./types";
import axios from "axios";

export const getBlogs = (language, page, sort, searchQuery) => (dispatch) => {
    dispatch({ type: LOADING_BLOGS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/blogs/${language}?page=${page}&sort=${sort}&searchQuery=${searchQuery}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_BLOGS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_BLOGS,
          payload: [],
        });
      });
  };

export const getMostSeenBlogs = (language) => (dispatch) => {
    dispatch({ type: LOADING_BLOGS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/blogs/mostseen/${language}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_MOST_SEEN_BLOGS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_BLOGS,
          payload: [],
        });
      });
  };

export const editBlogVues = (id, newData) => (dispatch) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/blogs/blog/count/${id}`, newData)
      .then((res) => {
        dispatch(getBlogs());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_BLOGS,
          payload: [],
        });
      });
};

export const getAllBlogs = () => (dispatch) => {
  dispatch({ type: LOADING_BLOGS });
  axios
    .get(`${process.env.REACT_APP_API_URL}/blogs`)
    .then((res) => {
      dispatch({
        type: SUCCESS_GET_ALL_BLOGS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERROR_BLOGS,
        payload: [],
      });
    });
};