import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Navigation } from 'swiper';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getSlides } from '../actions';

import hero from '../assets/hero.png';

function DefaultSlider({ t }) {
  return (
    <SwiperSlide>
      <div className="relative">
        <img
          src={hero}
          className="lg:object-fill object-cover w-full h-screen"
          alt="EdenMed"
        />
        <main>
          <div className="absolute inset-0 mx-auto px-6 lg:px-8">
            <div className="mx-auto max-w-5xl pt-20 pb-32 sm:pt-24 sm:pb-40">
              <div className="bg-[#000]/30 rounded-lg">
                <div className="p-8">
                  <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl text-white">
                    {t('home_hero_title')}
                  </h1>
                  <br />
                  <p className="mt-6 text-lg leading-8 text-white sm:text-center">
                    {t('home_hero_description')}
                  </p>
                  <div className="sm:mb-8 flex justify-center mt-16">
                    <div className="relative overflow-hidden rounded-full py-1.5 px-4 text-sm leading-6 ring-1 ring-white bg-[#B7CD00] hover:bg-[#fff] text-white hover:text-[#B7CD00] cursor-pointer hover:font-medium transition duration-800">
                      <span className={``}>
                        {t('home_hero_cta_text')}.{' '}
                        <Link
                          to="/events"
                          className="font-semibold text-edenmedciel"
                        ></Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SwiperSlide>
  );
}

function Slider(props) {
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng');

  useEffect(() => {
    props.getSlides(language);
  }, [language]);

  const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }

  const setTitleClassName = (size) => {
    const textSize = `text-2xl`;
    const textSizeSm = `sm:text-${size-1}xl lg:text-${size}xl`;
    let className = `${textSize} ${textSizeSm}  font-bold tracking-tight sm:text-center`;
    return className;
  };

  const setSubTitleClassName = (size) => {
    let adaptedForTailwind = size === 1 ? 'text-xl' : `sm:text-${size}xl text-xl`;
    let className = `${adaptedForTailwind} leading-8 sm:text-center sm:mt-6 mt-4`;
    return className;
  };

  const setBackgroundColor = (backgroundColor) => {
    let className = `${backgroundColor} rounded-md`;
    return className;
  };

  return (
    <div className="relative">
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        loop={true}
        navigation={true}
        autoplay={{
          delay: props.slideAutoplayDelay,
        }}
        modules={[Autoplay, Navigation]}
      >
        {props.loadingSlides ? (
          <DefaultSlider t={t} />
        ) : (
          <>
            {props.slides.map((item) => (
              <SwiperSlide key={item._id}>
                <div className="relative">
                  <img
                    src={item.imageUrl}
                    className="lg:object-fill object-cover w-full h-[600px]"
                    alt="EdenMed"
                    referrerPolicy="no-referrer"
                  />
                  <main>
                    <div className="absolute inset-0 mx-auto px-6 lg:px-8">
                      <div className="mx-auto max-w-5xl pt-16 pb-16 lg:pt-28 lg:pb-40">
                        <div
                          className="rounded-md"
                          style={{
                            background: `rgba(${item.backgroundColor.r}, ${item.backgroundColor.g}, ${item.backgroundColor.b}, ${item.backgroundColor.a})`,
                          }}
                        >
                          <div className="p-6">
                            <h1
                              style={{
                                color: `rgba(${item.titleColor.r}, ${item.titleColor.g}, ${item.titleColor.b}, ${item.titleColor.a})`,
                              }}
                              className={setTitleClassName(item.titleSize)}
                              dir={isArabic(item.title) ? "rtl" : undefined}
                            >
                              {item.title}
                            </h1>
                            <br />
                            <p
                              style={{
                                color: `rgba(${item.subTitleColor.r}, ${item.subTitleColor.g}, ${item.subTitleColor.b}, ${item.subTitleColor.a})`,
                              }}
                              className={setSubTitleClassName(
                                item.subTitleSize
                              )}
                              dir={isArabic(item.subTitle) ? "rtl" : undefined}
                            >
                              {item.subTitle}
                            </p>
                            <div className="sm:mb-8 flex justify-center sm:mt-16 mt-11">
                              <div className="relative overflow-hidden rounded-full py-1.5 px-4 sm:text-sm text-xs leading-6 ring-1 ring-white bg-[#B7CD00] hover:bg-[#fff] text-white hover:text-[#B7CD00] hover:font-medium transition duration-800 cursor-pointer">
                                <span>
                                  <Link
                                    to="/events"
                                    className="font-semibold"
                                  >
                                    {t('home_hero_cta_text')}.
                                  </Link>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  slides: state.home.slides,
  loadingSlides: state.home.loadingSlides,
  slideAutoplayDelay: state.home.slideAutoplayDelay,
});

const mapActionsToProps = {
  getSlides,
};
export default connect(mapStateToProps, mapActionsToProps)(Slider);
