import React from 'react'
import Anpe from "../assets/anpe-logo.png";
import Ciffip from "../assets/ciffip-logo.png";
import Agrigento from "../assets/agrigento-logo.png";
import Cnr from "../assets/cnr-logo.png";
import Arpa from "../assets/arpa-logo.png";
import Orsa from "../assets/orsa-logo.png";

const Partenareat = () => {
  return (
    <div className="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl px-4 md:px-8">
        <div className="overflow-hidden">
            <div className="flex justify-around animate-mover w-[1280px]">
                <div className="h-14">
                    <a
                        href="http://www.environnement.gov.tn/index.php/fr/agence-nationale-de-protection-de-l-environnement/"
                        target="_blank"
                        rel="noreferrer"
                        >
                        <img
                            className="object-fill h-14"
                            src={Anpe}
                            alt="anpe"
                        />
                    </a>
                </div>
                <div className="h-14">
                    <a
                        href="http://www.ciffip.tn/fr/"
                        target="_blank"
                        rel="noreferrer"
                        >
                        <img
                            className="object-fill h-14"
                            src={Ciffip}
                            alt="ciffip"
                            />
                    </a>
                </div>
                <div className="h-14">
                    <a
                        href="https://www.unipa.it/amministrazione/politerritoriali/poloterritorialeagrigento/"
                        target="_blank"
                        rel="noreferrer"
                        >
                        <img
                            className="object-fill h-14"
                            src={Agrigento}
                            alt="agrigento"
                            />
                    </a>
                </div>
                <div className="h-14">
                    <a
                        href="https://www.cnr.it/it/istituto/124/articolazione-territoriale/istituto-per-lo-studio-degli-impatti-antropici-e-sostenibilita-in-ambiente-marino-ias"
                        target="_blank"
                        rel="noreferrer"
                        >
                        <img className="object-fill h-14" src={Cnr} alt="cnr" />
                    </a>
                </div>
                <div className="h-14">
                    <a
                        href="https://www.arpa.sicilia.it/"
                        target="_blank"
                        rel="noreferrer"
                        >
                        <img
                            className="object-fill h-14"
                            src={Arpa}
                            alt="arpa"
                            />
                    </a>
                </div>
                <div className="h-14">
                    <a
                        href="https://orsaconsulting.it/"
                        target="_blank"
                        rel="noreferrer"
                        >
                        <img
                            className="object-fill h-14"
                            src={Orsa}
                            alt="orsa"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Partenareat