import { LOADING_MEDIAS, 
          SUCCESS_GET_MEDIAS, 
          SET_ERROR_MEDIAS, 
          LOADING_DOCUMENTS, 
          SUCCESS_GET_DOCUMENTS, 
          SET_ERROR_DOCUMENTS, 
          LOADING_PHOTOS,
          SUCCESS_GET_PHOTOS,
          SET_ERROR_PHOTOS,
          RESET } from "./types";
const initialState = {
  loading: false,
  data: [],
  error: false,
};

export const mediasReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_MEDIAS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_MEDIAS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
      };

    case SET_ERROR_MEDIAS:
      return {
        ...state,
        loading: false,
        error: true,
        data: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
      };

    default:
      return state;
  }
}


export const documentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_DOCUMENTS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
      };

    case SET_ERROR_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: true,
        data: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
      };

    default:
      return state;
  }
}

export const photosReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_PHOTOS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_PHOTOS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
      };

    case SET_ERROR_PHOTOS:
      return {
        ...state,
        loading: false,
        error: true,
        data: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
      };

    default:
      return state;
  }
}



