import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import Loading from '../../../components/Loading';
import { getFaqs } from "../actions";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex gap-2 items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-md text-left font-medium w-11/12">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? 'transform rotate-180' : ''
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};
const Main = (props) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng")

  useEffect(() => {
    props.getFaqs(language)
  }, [language])
  
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 lg:py-16">
      <div className="flex mb-16">
        <h2 className="max-w-md font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
          {t("home_faq_title")}
        </h2>
      </div>
      <div className="flex mb-16">
        <h2 className="max-w-md font-sans text-lg font-normal tracking-tight sm:text-xl sm:leading-none xl:max-w-lg">
          {t("home_faq_description")}
        </h2>
      </div>
      {/*  */}
      {props.faq.loading 
        ?
          <div className="space-y-4 flex flex-col">
              <Loading height={"h-[100px]"} />
              <Loading height={"h-[100px]"} />
              <Loading height={"h-[100px]"} />
              <Loading height={"h-[100px]"} />
              <Loading height={"h-[100px]"} />
          </div>
        :
          <div className="space-y-4 md:col-span-2 col-span-3">
            {props.faq.data.slice(0,5).map((item)=>(
              <Item title={item.question} key={item._id}>
                {item.response}
              </Item>
            ))}
          </div>
        }
    </div>
  );
}

const mapStateToProps = (state) => ({
  faq: state.faq,
});

const mapActionsToProps = {
  getFaqs,
};
export default connect(mapStateToProps, mapActionsToProps)(Main);
