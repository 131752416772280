import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { BsFillEmojiFrownFill } from "react-icons/bs";

import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getBlogs, editBlogVues } from "../actions";

import "../../../App.css";
import Loading from '../../../components/Loading';

const Pagination = (props) => {
    const { t } = useTranslation();
    const language = localStorage.getItem("i18nextLng");
    const months = language === "fr" ? ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"] : ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState(-1);
    const [searchQuery, setSearchQuery] = useState("");
    const description_max_length = 120;
    const title_max_length = 44;

    useEffect(() => {
        props.getBlogs(language,page, sort, searchQuery)
    }, [language, page, sort, searchQuery])

    const handlePageClick = (e) => {
        setPage(e.selected+1)
    };

    const handleSearchQuery = (e) => {
        setSearchQuery(e.target.value)
    };

    const handleSort = (e) => {
        setSort(e.target.value)
    };

    const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }

  return (
    <div>
        <div className="flex justify-end gap-2 px-4 mt-10">
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                </div>
                <input 
                    type="text" 
                    className="bg-white border border-gray-200 focus:outline-none focus:border-edenmedciel text-gray-400 text-base rounded-md block w-full px-3 py-2 pl-10" 
                    onChange={handleSearchQuery}
                    placeholder={t("blogs_search")} />
            </div>
            <div>
                <select
                    onChange={handleSort}
                    className="bg-white border border-gray-200 border-r-8 border-r-transparent focus:border-r-transparent focus:outline-none text-gray-400 px-3 py-2 cursor-pointer text-base rounded-md">
                        <option value={-1}>{t("blogs_tri_up")}</option>
                        <option value={1}>{t("blogs_tri_down")}</option>
                </select>
            </div>
        </div>
        
        {searchQuery && props?.blogs?.data?.length === 0 
            ?   
                <div className="flex flex-col items-center gap-5 py-40"> 
                    <p>
                        <BsFillEmojiFrownFill className="w-40 h-40 text-edenmedvert" />
                    </p>
                    <p className="text-center text-lg font-semibold text-edenmedvert">aucuns résultats trouvés!</p>
                </div>
            : 
                <>
                    {props.blogs.loading ?
                        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:max-w-full h-full mt-4 px-4 py-4">
                            <Loading height={"h-[450px]"} />
                            <Loading height={"h-[450px]"} />
                            <Loading height={"h-[450px]"} />
                            <Loading height={"h-[450px]"} />
                        </div>
                    :
                        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:max-w-full h-full mt-4 px-4 pb-4">
                            {props?.blogs?.data?.map((item) => (
                                <div key={item._id} className="h-full bg-white rounded-md shadow-md hover:shadow-lg">
                                    <div className="">
                                        <img
                                        className="object-fill w-full sm:h-64 h-48 rounded-tl-md rounded-tr-md"
                                        src={item.imageUrl} 
                                        alt={item.title}
                                        referrerPolicy="no-referrer"
                                        />
                                    </div>
                                    <div className="">
                                        <div className="p-4">
                                            <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                                                <span className="text-edenmedciel">{new Date(item.date).getDate()} {months[new Date(item.date).getMonth()]} {new Date(item.date).getFullYear()}</span>
                                            </p>
                                            <Link
                                                to={`/articles/${item._id}`}
                                                onClick={() => props.editBlogVues(item._id, {vues:item.vues+1})}
                                                className={`inline-block mb-3 h-14 overflow-hidden text-xl font-bold text-edenmedbleu hover:underline ${isArabic(item.title) && "text-right"}`}
                                                dir={isArabic(item.title) ? "rtl" : undefined}
                                            >
                                                {item.title.length > title_max_length ? <span>{item.title.slice(0,title_max_length)}...</span> : <span>{item.title}</span>}
                                            </Link>
                                            <p className={`mb-2 h-24 overflow-hidden text-gray-700 ${isArabic(item.description) && "text-right"}`} dir={isArabic(item.description) ? "rtl" : undefined}>
                                                {item.description.length > description_max_length ? <span>{item.description.slice(0,description_max_length)}...</span> : <span>{item.description}</span>}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={4}
                            pageCount={props.blogs.numberOfPages}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            containerClassName="pagination"
                            pageLinkClassName="page-num"
                            previousLinkClassName="page-num"
                            nextLinkClassName="page-num"
                            activeLinkClassName="active"
                        />
                </>
        }
    </div>
  )
}

const mapStateToProps = (state) => ({
    blogs: state.blogs,
  });
  
const mapActionsToProps = {
    getBlogs, editBlogVues
};
export default connect(mapStateToProps, mapActionsToProps)(Pagination);