import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getDocuments } from "../actions";
import { useTranslation } from "react-i18next";
import Loading from '../../../components/Loading';


const Documents = (props) => {
    const { t } = useTranslation();
    const language = localStorage.getItem("i18nextLng")
    const description_max_length = 100;
    const title_max_length = 35;


    useEffect(() => {
        props.getDocuments(language)
    }, [language])

    const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }
  return (
    <div className="">
      {props.documents.loading ? (
        <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-2 max-w-full">
          <Loading height={'h-[400px]'} />
          <Loading height={'h-[400px]'} />
          <Loading height={'h-[400px]'} />
          <Loading height={'h-[400px]'} />
        </div>
      ) : (
        <div className="grid gap-8 lg:grid-cols-4 md:grid-cols-2 max-w-full">
          {props.documents.data.map((item) => (
            <div
              key={item._id}
              className="overflow-hidden transition-shadow duration-300 bg-white rounded shadow-sm"
            >
              <img
                src={item.imageUrl}
                className="object-cover w-full h-48"
                alt="avatar"
                referrerPolicy="no-referrer"
              />
              <div className="p-4 border-t-0 flex flex-col h-60">
                <h1 className="inline-block mb-2 h-14 text-lg font-bold transition-colors duration-200 hover:text-deep-purple-accent-700">
                  {item.title.length > title_max_length ? (
                    <span>{item.title.slice(0, title_max_length)}...</span>
                  ) : (
                    <span>{item.title}</span>
                  )}
                </h1>
                <p
                  className={`text-gray-700 mb-3 ${
                    isArabic(item.description) && 'text-justify'
                  }`}
                  dir={isArabic(item.description) ? 'rtl' : undefined}
                >
                  {item.description.length > description_max_length ? (
                    <span>
                      {item.description.slice(0, description_max_length)}...
                    </span>
                  ) : (
                    <span>{item.description}</span>
                  )}
                </p>
                <div className="flex justify-between mt-auto">
                  <div className="relative overflow-hidden w-fit rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel ring-1 hover:bg-white hover:ring-edenmedciel text-white hover:text-edenmedciel">
                    <button
                      onClick={(e) => {
                        window.open(`${item.fileUrl}&export=download`, '_self');
                      }}
                      className="font-semibold"
                    >
                      Télécharger
                    </button>
                  </div>
                  <div className="relative overflow-hidden w-fit rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel ring-1 hover:bg-white hover:ring-edenmedciel text-white hover:text-edenmedciel">
                    <a
                      href={item.fileUrl}
                      download="test"
                      className="font-semibold"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('cta_lire_plus')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
    documents: state.documents,
  });
  
  const mapActionsToProps = {
    getDocuments,
  };
  export default connect(mapStateToProps, mapActionsToProps)(Documents);