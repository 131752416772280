import React from 'react'
import { GoLocation } from "react-icons/go";

const Lieu = ({address, addressImage}) => {
  return (
    <div className="relative w-full">
      <div className="w-full h-screen">
        <img 
            className="object-cover w-full h-full" 
            src={addressImage}
            alt="hero"
            referrerPolicy="no-referrer" />
      </div>
      <div className="absolute flex flex-col justify-center gap-1 md:left-[10%] left-0 bottom-0 lg:w-[35%] md:w-[47%] p-10 bg-[rgb(0,0,0,0.5)]">
        <p className="text-white text-2xl font-medium">Adresse</p>
        <p className="text-white text-base">Eden-med aura le plaisir de vous inviter à participer à cet événement et à célébrer ensemble la fête. Soyez nombreux à :</p>
        <div className="flex gap-2 text-white text-xl items-center">
            <p><GoLocation /></p>
            <p>{address}</p>
        </div>
      </div>
    </div>
  )
}

export default Lieu