import { GiSandsOfTime } from "react-icons/gi";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getMostSeenBlogs, editBlogVues } from "../../articles/actions";

import { useEffect } from "react";
import Loading from "../../../components/Loading";

const Article = (props) => {
    const { t } = useTranslation();
    const language = localStorage.getItem("i18nextLng");
    const months = language === "fr" ? ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"] : ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const description_max_length = 120;
    const title_max_length = 50;
  
    useEffect(() => {
      props.getMostSeenBlogs(language) 
    }, [language])

    const countDays = (d) => {
        return Math.floor((Date.parse(new Date()) - Date.parse(d)) / 86400000)
    }

    const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }

    return (
      <div className="px-0 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-2 lg:px-2 lg:py-20">
        <div className="flex flex-row mb-6 md:mb-10 justify-between px-4 md:px-6 lg:px-6">
          <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
          {t("home_article_title")}
          </h2>
          <div className="hidden md:block">
            <div className="relative overflow-hidden rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel hover:bg-white ring-1 hover:ring-edenmedgris">
                <span className="text-gray-600">
                <Link to="articles" className="font-semibold text-white hover:text-edenmedciel">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {t("home_article_btn")} <span aria-hidden="true">&rarr;</span>
                </Link>
                </span>
            </div>
          </div>
        </div>
        {/*  */}
        {props.blogs.loading 
            ?
                <div className="grid gap-8 md:grid-cols-2 xl:grid-cols-3 lg:max-w-full bg-gray-200 md:p-6 p-4">
                    <Loading height={"h-[450px]"} />
                    <Loading height={"h-[450px]"} />
                    <Loading height={"h-[450px]"} />
                </div>
            :
                <div className="grid gap-8 md:grid-cols-2 xl:grid-cols-3 lg:max-w-full bg-gray-200 md:p-6 p-4">
                    {props?.blogs?.mostSeenBlogs?.sort((a,b) => (a.vues > b.vues) ? -1 : ((b.vues > a.vues) ? 1 : 0)).slice(0,3).map((item) => (
                        <div key={item._id} className="relative">
                            <div className="">
                                <img
                                src={item.imageUrl}
                                className="object-fill w-full h-64"
                                alt={item.title}
                                referrerPolicy="no-referrer"
                                />
                            </div>
                            <div className="relative sm:-mr-[2%] md:-mr-[3%] sm:ml-[10%] sm:-mt-8 z-10 bg-white sm:rounded-tl-md sm:rounded-bl-md">
                                <div className="sm:px-4 px-5 sm:py-6 py-5">
                                    <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                                        <span className="text-edenmedciel">{new Date(item.date).getDate()} {months[new Date(item.date).getMonth()]} {new Date(item.date).getFullYear()}</span>
                                    </p>
                                    <Link
                                        to={`/articles/${item._id}`}
                                        onClick={() => props.editBlogVues(item._id, {vues:item.vues+1})}
                                        aria-label="Category"
                                        className={`inline-block mb-3 h-16 overflow-hidden text-2xl font-bold text-edenmedbleu hover:underline ${isArabic(item.title) && "text-right"}`}
                                        dir={isArabic(item.title) ? "rtl" : undefined}
                                    >
                                        {item.title.length > title_max_length ? <span>{item.title.slice(0,title_max_length)}...</span> : <span>{item.title}</span>}
                                    </Link>
                                    <p className={`mb-2 h-20 overflow-hidden text-gray-700 ${isArabic(item.description) && "text-right"}`} dir={isArabic(item.description) ? "rtl" : undefined}>
                                        {item.description.length > description_max_length ? <span>{item.description.slice(0,description_max_length)}...</span> : <span>{item.description}</span>}
                                    </p>
                                </div>
                                <div className="p-5 border-t">
                                    <p className="text-xs font-semibold tracking-wide">
                                        <span className="text-gray-700 flex items-center"> 
                                            <span><AiOutlineEye className="h-4 w-4 mr-2"/></span>
                                                {item.vues}&nbsp;{item.vues === 1 ? <span>{t("home_article_vue")}</span> : <span>{t("home_article_vues")}</span>} 
                                            <span className="mx-3">|</span>
                                            <span><GiSandsOfTime className="h-5 w-5 mr-2"/></span>
                                            {countDays(item.createdAt) <= 1 ? `1 ${t("home_article_day")}` 
                                                                            : 1 <= countDays(item.createdAt) <= 31 ? `${countDays(item.createdAt)} ${t("home_article_days")}` 
                                                                            : 32 <= countDays(item.createdAt) <= 365 ? `${Math.floor(countDays(item.createdAt)/31)} ${t("home_article_months")}`
                                                                            : 366 <= countDays(item.createdAt) <= 731 ? `1 ${t("home_article_year")}`
                                                                            : `${Math.floor(countDays(item.createdAt)/365)} ${t("home_article_years")}`}  
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            }
        <div className="md:hidden flex mt-12 justify-center">
            <div className="relative overflow-hidden w-fit rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel hover:bg-white ring-1 hover:ring-edenmedgris">
                <span className="text-gray-600">
                <Link to="articles" className="font-semibold text-white hover:text-edenmedciel">
                    <span className="absolute inset-0" aria-hidden="true" />
                    {t("home_article_btn")} <span aria-hidden="true">&rarr;</span>
                </Link>
                </span>
            </div>
        </div>
      </div>
    );
  }
  
const mapStateToProps = (state) => ({
    blogs: state.blogs,
});

const mapActionsToProps = {
    getMostSeenBlogs, editBlogVues
};
export default connect(mapStateToProps, mapActionsToProps)(Article);