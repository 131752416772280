import React from 'react'
import approche1 from "../assets/innovation-1.png"
import approche2 from "../assets/innovation-2.png"
import approche3 from "../assets/innovation-3.png"


const Approche = () => {
  return (
    <div className="mt-24">
      <div className="mb-16">
        <h2 className="font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none">
          Approche innovante d'EdEn-MED
        </h2>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-8">
        <div>
          <img
            className="object-fill w-full rounded shadow-lg"
            src={approche1}
            alt="zone de couverture"
          />
        </div>
        <div>
          <img
            className="object-fill w-full rounded shadow-lg"
            src={approche2}
            alt="zone de couverture"
          />
        </div>
        <div>
          <img
            className="object-fill w-full rounded shadow-lg"
            src={approche3}
            alt="zone de couverture"
          />
        </div>
      </div>
    </div>
  )
}

export default Approche