import {
  LOADING_MEMBERS,
  SUCCESS_GET_MEMBERS,
  SET_ERROR,
  RESET,
} from './types';
const initialState = {
  loading: false,
  members: [],
  error: false,
};

export const aboutReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_MEMBERS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_MEMBERS:
      return {
        ...state,
        loading: false,
        members: payload,
        error: false,
      };
    
    case SET_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        members: [],
      };

    default:
      return state;
  }
};
