import { GET_SURVEY, LOADING, SET_ERRORS, SUCCESS_FEEDBACK, FAIL_FEEDBACK} from './types';

import axios from 'axios';

export const list = (language) => (dispatch) => {
  dispatch({ type: LOADING });
  axios
    .get(`${process.env.REACT_APP_API_URL}/surveys/${language}`)
    .then((res) => {
      dispatch({
        type: GET_SURVEY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: [],
      });
    });
};

export const getSurveyDetails = (id) => (dispatch) => {
  dispatch({ type: LOADING });
  axios
    .get(`${process.env.REACT_APP_API_URL}/surveys/survey/${id}`)
    .then((res) => {
      dispatch({
        type: GET_SURVEY,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: [],
      });
    });
};

export const sendFeedback = (feedbackData) => (dispatch) => {
  dispatch({ type: LOADING });
  axios
    .post(`${process.env.REACT_APP_API_URL}/feedbacks`, feedbackData)
    .then((res) => {
      dispatch({
        type: SUCCESS_FEEDBACK,
        payload: "Votre feedback a été envoyé avec succès",
      });
    })
    .catch((err) =>
      dispatch({
        type: FAIL_FEEDBACK,
        payload: "Une erreur s'est produite veuillez réessayer",
      })
    );
};