import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getLastEvents } from '../../events/actions';
import { useEffect } from 'react';
import Loading from '../../../components/Loading';

const Events = (props) => {
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng');
  const months =
    language === 'fr'
      ? [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Aout',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ]
      : [
          'Gennaio',
          'Febbraio',
          'Marzo',
          'Aprile',
          'Maggio',
          'Giugno',
          'Luglio',
          'Agosto',
          'Settembre',
          'Ottobre',
          'Novembre',
          'Dicembre',
        ];
  const description_max_length = 120;
  const title_max_length = 50;

  useEffect(() => {
    props.getLastEvents(language);
  }, [language]);

  const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 lg:py-20">
      <div className="flex flex-row mb-6 md:mb-10 justify-between">
        <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
          {t('home_event_title')}
        </h2>
        <div className="hidden md:block">
          <div className="relative overflow-hidden rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel hover:bg-white ring-1 hover:ring-edenmedgris">
            <span className="text-gray-600">
              <Link
                to="events"
                className="font-semibold text-white hover:text-edenmedciel"
              >
                <span className="absolute inset-0" aria-hidden="true" />
                {t('home_article_btn')} <span aria-hidden="true">&rarr;</span>
              </Link>
            </span>
          </div>
        </div>
      </div>
      {/*  */}
      {props.events.loading ? (
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:max-w-full">
          <Loading height={'h-[450px]'} />
          <Loading height={'h-[450px]'} />
          <Loading height={'h-[450px]'} />
        </div>
      ) : (
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 lg:max-w-full">
          {props?.events?.lastEvents?.map((item) => (
            <div
              key={item._id}
              className="overflow-hidden bg-white rounded border border-gray-300 shadow-sm transform translate-y-2 hover:translate-y-0 duration-500 ease-in-out"
            >
              <img
                src={item.imageUrl}
                className="object-cover w-full h-64"
                alt={item.title}
                referrerPolicy="no-referrer"
              />
              <div className="p-5">
                <p className="flex gap-1 mb-3 text-xs font-semibold tracking-wide uppercase">
                  <span className="transition-colors duration-200 text-edenmedciel hover:text-deep-purple-accent-700">
                    {item.addressGovernorate}
                  </span>
                  <span className="text-edenmedciel">
                    - {new Date(item.date).getDate()}{' '}
                    {months[new Date(item.date).getMonth()]}{' '}
                    {new Date(item.date).getFullYear()}
                  </span>
                </p>
                <p className={`inline-block mb-3 h-16 overflow-hidden text-2xl font-bold text-edenmedbleu transition-colors duration-200 hover:text-deep-purple-accent-700 ${isArabic(item.title) && "text-right"}`}
                   dir={isArabic(item.title) ? "rtl" : undefined}>
                  {item.title.length > title_max_length ? (
                    <span>{item.title.slice(0, title_max_length)}...</span>
                  ) : (
                    <span>{item.title}</span>
                  )}
                </p>
                <p className={`mb-3 h-24 overflow-hidden text-gray-700 ${isArabic(item.description) && "text-right"}`}
                   dir={isArabic(item.description) ? "rtl" : undefined}>
                  {item.description.length > description_max_length ? (
                    <span>
                      {item.description.slice(0, description_max_length)}...
                    </span>
                  ) : (
                    <span>{item.description}</span>
                  )}
                </p>
                <div className="relative overflow-hidden w-fit rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel ring-1 hover:bg-white hover:ring-edenmedgris">
                  <span className="text-gray-600">
                    <Link
                      to={`/events/${item._id}`}
                      className="font-semibold text-white hover:text-edenmedciel"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      {t('cta_lire_plus')}{' '}
                      <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="md:hidden flex mt-12 justify-center">
        <div className="relative overflow-hidden w-fit rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel hover:bg-white ring-1 hover:ring-edenmedgris">
          <span className="text-gray-600">
            <Link
              to="events"
              className="font-semibold text-white hover:text-edenmedciel"
            >
              <span className="absolute inset-0" aria-hidden="true" />
              {t('home_article_btn')} <span aria-hidden="true">&rarr;</span>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  events: state.events,
});

const mapActionsToProps = {
  getLastEvents,
};
export default connect(mapStateToProps, mapActionsToProps)(Events);
