export const LOADING_SLIDES = 'LOADING_SLIDES';
export const SUCCESS_GET_SLIDES = 'SUCCESS_GET_SLIDES';

export const LOADING_VISITORS = 'LOADING_VISITORS';
export const SUCCESS_ADD_VISITORS = 'SUCCESS_ADD_VISITORS';
export const SUCCESS_GET_VISITORS = 'SUCCESS_GET_VISITORS';

export const LOADING_TESTIMONIALS = 'LOADING_TESTIMONIALS';
export const SUCCESS_GET_TESTIMONIALS = 'SUCCESS_GET_TESTIMONIALS';
export const RESET = 'RESET';

export const LOADING_PUPUP = 'LOADING_PUPUP';
export const SUCCESS_GET_PUPUP = 'SUCCESS_GET_PUPUP';

export const SET_ERROR = 'SET_ERROR';
