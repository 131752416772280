import React from 'react'
import EnqueteButton from '../../utils/EnqueteButton';

import Main from './components/Main'
import banner from "../../assets/images/faq-banner.png";


const Faq = () => {
  return (
    <div className='relative'>
      <EnqueteButton />
      <div>
          <div className="w-full sm:h-72 h-52 border-b-[1px] border-b-edenmedvert">
            <img 
              className="object-fill w-full h-full" 
              src={banner}
              alt="hero" />
          </div>
          <Main />
      </div>
    </div>
  )
}

export default Faq