import React, { useState } from 'react';
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { IoIosPeople } from "react-icons/io";
import { RiShutDownLine } from "react-icons/ri";
import { MdOutlineKeyboardVoice, MdOutlineWatchLater } from "react-icons/md";

const Apropos = ({about, attendance, duration, session, speakers}) => {
  const [counterOn, setCounterOn] = useState(false)

  const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10">
      <div className="flex flex-col justify-center gap-4 mb-8">
        <h1 className="text-edenmedvert text-center text-3xl font-semibold uppercase">A propos l'évènement</h1>
        <p className={`text-center text-lg font-normal md:px-10 lg:px-20 ${isArabic(about) && "text-right"}`}
           dir={isArabic(about) ? "rtl" : "ltr"}>
            {about && 
            <>
                {about.split("#").map((item, index)=>(
                    <>
                      {
                       index%2===0 
                      ? 
                       <span style={{ whiteSpace: 'pre-line' }}>
                        {item}
                       </span> 
                      : 
                       <a href={item} 
                          style={{color: "blue"}} 
                          target="_blank" 
                          rel="noreferrer">
                        {item}</a>
                      }
                    </>
                ))}
            </>}
        </p>
      </div>
      <ScrollTrigger onEnter={()=>setCounterOn(true)}>
        {counterOn && 
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
            <div className="text-center">
              <h6 className="text-4xl font-normal mb-4">
                <CountUp start={0} end={attendance} duration={4} delay={0}/>
              </h6>
              <div className="flex items-center justify-center w-12 h-12 mx-auto">
                <IoIosPeople className="text-edenmedciel w-12 h-12" />
              </div>
              <p className="mb-2 font-bold text-md uppercase">participants</p>
            </div>
            <div className="text-center">
              <h6 className="text-4xl font-normal mb-4">
              <CountUp start={0} end={duration} duration={4} delay={0}/>
              </h6>
              <div className="flex items-center justify-center w-12 h-12 mx-auto">
                <MdOutlineWatchLater className="text-edenmedciel w-10 h-10" />
              </div>
              <p className="mb-2 font-bold text-md uppercase">programme</p>
            </div>
            <div className="text-center">
              <h6 className="text-4xl font-normal mb-4">
              <CountUp start={0} end={session} duration={4} delay={0}/>
              </h6>
              <div className="flex items-center justify-center w-12 h-12 mx-auto">
                <RiShutDownLine className="text-edenmedciel w-10 h-10" />
              </div>
              <p className="mb-2 font-bold text-md uppercase">sessions</p>
            </div>
            <div className="text-center">
              <h6 className="text-4xl font-normal mb-4">
              <CountUp start={0} end={speakers} duration={4} delay={0}/>
              </h6>
              <div className="flex items-center justify-center w-12 h-12 mx-auto">
                <MdOutlineKeyboardVoice className="text-edenmedciel w-10 h-10" />
              </div>
              <p className="mb-2 font-bold text-md uppercase">conférenciers</p>
            </div>
          </div>
        }
      </ScrollTrigger>
    </div>
  )
}

export default Apropos