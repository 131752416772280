import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getLastEvents } from "../actions";
import { getParticipants } from "../../eventDetails/actions";
import Main from './Main';


const NextEvent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [id, setID] = useState("");
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng");
  const days = language === "fr" ? ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"] : ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"];
  const months = language === "fr" ? ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"] : ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];

  useEffect(() => {
      props.getLastEvents(language)
  }, [language])

  const isOccurred = new Date(props.events.lastEvents[0]?.date) > new Date()

  const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }

  return (
    <div className="relative w-full md:px-0">
        <div className="w-full h-[580px] md:rounded-md border border-gray-100 shadow-sm overflow-hidden">
            <img 
                src={props.events.lastEvents[0]?.imageUrl} 
                alt="newevent"
                className="w-full h-full sm:object-fill object-cover md:rounded-md"/>
        </div>
        <div className="absolute lg:left-16 lg:top-18 md:left-12 md:top-18 sm:left-8 sm:top-16 left-4 top-8 lg:w-[60%] md:w-3/4 sm:w-5/6 w-11/12 flex flex-col lg:gap-6 md:gap-3 gap-3">
          <div className='flex flex-col sm:gap-3 gap-1 bg-[#5f5f5c7c] rounded-md p-6'> 
            <Link 
              to={`/events/${props.events.lastEvents[0]?._id}`} 
              className={`font md:max-w-fit max-w-[80%] sm:text-4xl text-3xl font-bold tracking-tight text-white hover:underline rounded-md p-2 ${isArabic(props.events.lastEvents[0]?.title) && "text-right"}`}
              dir={isArabic(props.events.lastEvents[0]?.title) ? "rtl" : undefined}>
                {props.events.lastEvents[0]?.title}
            </Link>
            <p className={`mt-4 md:max-w-fit max-w-[85%] sm:text-xl text-lg text-white rounded-md p-2 ${isArabic(props.events.lastEvents[0]?.invitation) && "text-right"}`}
               dir={isArabic(props.events.lastEvents[0]?.invitation) ? "rtl" : undefined}>
              {props.events.lastEvents[0]?.invitation}
            </p>
            <p className="mt-4 w-fit sm:text-2xl text-1xl font-medium rounded-md p-2 text-white">
              {days[new Date(props.events.lastEvents[0]?.date).getDay()]} <strong>{new Date(props.events.lastEvents[0]?.date).getDate()} {months[new Date(props.events.lastEvents[0]?.date).getMonth()]} {new Date(props.events.lastEvents[0]?.date).getFullYear()}</strong> à <strong>{props.events.lastEvents[0]?.time}</strong> 
            </p>
            <div className="relative flex gap-6 w-fit mt-4">
                <Link 
                  to={`/events/${props.events.lastEvents[0]?._id}`} 
                  className="font-bold w-fit rounded-md sm:text-3xl text-xl py-2 px-6 text-white hover:text-edenmedciel  bg-edenmedciel ring-1 hover:bg-white hover:ring-edenmedgris"
                  >
                    {t("cta_lire_plus")}
                </Link>
                {
                  isOccurred 
                  && 
                  <button 
                    className="font-bold w-fit rounded-md sm:text-3xl text-xl py-2 px-6 text-white hover:text-edenmedciel  bg-edenmedciel ring-1 hover:bg-white hover:ring-edenmedgris"
                    onClick={() => {setOpenModal(!openModal);setID(props.events.lastEvents[0]?._id); props.getParticipants()}}>
                      {t("event_participate_cta")}
                  </button>
                }
                {openModal ? (
                <>
                  <div
                    className="fixed inset-0 w-screen h-screen bg-[rgb(0,0,0,0.5)] z-30"
                  >
                    <div
                      className="flex justify-center items-center fixed sm:w-[450px] w-80 inset-0 m-auto opacity-100 z-50"
                    >
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                          <h3 className="text-3xl font-semibold text-edenmedvert">
                            Participer à l'événement
                          </h3>
                          <div className="flex items-center justify-center p-3 border hover:border-edenmedciel text-white bg-edenmedciel hover:text-edenmedciel hover:bg-white rounded-full w-6 h-6">
                            <button
                              className="text-1xl flex items-center justify-center font-semibold h-6 w-6"
                              onClick={() => setOpenModal(false)}
                              >
                                X
                            </button>
                          </div>
                        </div>
                        <div className="relative p-6 flex-auto">
                          <Main 
                            id={id} 
                            title={props.events.lastEvents[0]?.title} 
                            description={props.events.lastEvents[0]?.description} 
                            imgUrl={props.events.lastEvents[0]?.imageUrl} 
                            date={props.events.lastEvents[0]?.date} 
                            setOpenModal={setOpenModal}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div> 
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  events: state.events,
});

const mapActionsToProps = {
  getLastEvents, getParticipants
};
export default connect(mapStateToProps, mapActionsToProps)(NextEvent);