import React from 'react'


const Sponsors = ({sponsors}) => {
  return (
    <div className="flex flex-col items-center overflow-hidden px-4 py-16 w-full lg:py-16">
        <div className="w-full pb-16">
            <h1 className="text-edenmedvert text-center text-3xl font-semibold uppercase">sponsors</h1>
        </div>
        <div className="flex gap-10 justify-around w-fit">
            {sponsors?.map((item)=>(
                <div key={item._id} className="h-20">
                    <a
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            className="object-fill h-20"
                            src={item.image}
                            alt="avatar"
                            referrerPolicy="no-referrer"
                        />
                    </a>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Sponsors