import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination } from 'swiper';
import Loading from '../../../components/Loading';

import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getBlogs, editBlogVues, getMostSeenBlogs } from "../actions";

const Favorites = (props) => {
    const { t } = useTranslation();
    const language = localStorage.getItem("i18nextLng")
    const months = language === "fr" ? ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"] : ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const description_max_length = 120;
    const title_max_length = 44;

    useEffect(() => {
        props.getMostSeenBlogs(language)
    }, [language])

    const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }

  return (
    <div>
        {props.blogs.loading ?
            <div className="grid lg:grid-cols-2 gap-4 grid-cols-1 p-4 pt-8">
                <Loading height={"h-[450px]"}/>
                <div className="flex gap-4">
                    <Loading height={"h-[450px]"}/>
                    <Loading height={"h-[450px]"}/>
                </div>
            </div>
            : 
            <>
                <div className="grid lg:grid-cols-2 gap-4 grid-cols-1 p-4">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                        clickable: true,
                        }}
                        autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                        }}
                        modules={[Autoplay, Pagination]}
                        className="w-full rounded-md shadow-md hover:shadow-lg"
                    >
                        {props.blogs.mostSeenBlogs.slice(0,10).map((item) => (
                            <SwiperSlide key={item._id} className="relative">
                                <img 
                                    src={item.imageUrl} 
                                    alt={item.title} 
                                    className="h-full object-fill"
                                    referrerPolicy="no-referrer"/>
                                <Link
                                    to={`/articles/${item._id}`}
                                    onClick={() => props.editBlogVues(item._id, {vues:item.vues+1})}
                                    className={`absolute top-[45%] left-[5%] z-10 font-sans md:text-4xl sm:text-3xl text-xl font-bold text-white hover:underline cursor-pointer ${isArabic(item.title) && "text-right right-[5%]"}`}>
                                    {item.title}
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="hidden sm:grid sm:gap-4 gap-4 sm:grid-cols-2 lg:max-w-full h-full">
                        {props.blogs.mostSeenBlogs.slice(0,2).map((item) => (
                            <div key={item._id} className="h-full bg-white rounded-md shadow-md hover:shadow-lg">
                                <div className="">
                                    <img
                                        src={item.imageUrl} 
                                        className="object-fill w-full sm:h-64 h-48 rounded-tl-md rounded-tr-md"
                                        alt={item.title} 
                                        referrerPolicy="no-referrer"
                                    />
                                </div>
                                <div className="">
                                    <div className="p-4">
                                        <p className="mb-3 text-xs font-semibold tracking-wide uppercase">
                                            <span className="text-edenmedciel">{new Date(item.date).getDate()} {months[new Date(item.date).getMonth()]} {new Date(item.date).getFullYear()}</span>
                                        </p>
                                        <Link
                                            to={`/articles/${item._id}`}
                                            onClick={() => props.editBlogVues(item._id, {vues:item.vues+1})}
                                            className={`inline-block mb-3 h-14 overflow-hidden text-xl font-bold text-edenmedbleu hover:underline ${isArabic(item.title) && "text-right"}`}
                                            dir={isArabic(item.title) ? "rtl" : undefined}
                                        >
                                            {item.title.length > title_max_length ? <span>{item.title.slice(0,title_max_length)}...</span> : <span>{item.title}</span>}
                                        </Link>
                                        <p className={`mb-2 h-24 overflow-hidden text-gray-700 ${isArabic(item.description) && "text-right"}`} dir={isArabic(item.description) ? "rtl" : undefined}>
                                            {item.description.length > description_max_length ? <span>{item.description.slice(0,description_max_length)}...</span> : <span>{item.description}</span>}
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>}
    </div>
  )
}

const mapStateToProps = (state) => ({
    blogs: state.blogs,
  });
  
const mapActionsToProps = {
getBlogs, editBlogVues, getMostSeenBlogs
};
export default connect(mapStateToProps, mapActionsToProps)(Favorites);