import React from 'react';
import { Link } from "react-router-dom";
import hero from "../assets/images/404-not-found.png"

const PageNotFound = () => {
  return (
    <div className="w-full h-screen flex flex-col gap-6 items-center pt-20 px-4">
      <div>
        <img src={hero} className="w-64" alt="avatar" />
      </div>
      <h1 className="text-3xl text-center font-semibold text-edenmedciel">404 non trouvé</h1>
      <h1 className="text-2xl text-center font-bold text-black">Whoops! Cette page n'existe pas!</h1>
      <Link to="/" className="px-6 py-2 rounded-md bg-edenmedciel text-white hover:bg-blue-600">Page d'accueil</Link>
    </div>
  )
}

export default PageNotFound