import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import LanguageSelect from '../utils/LanguageSelect';

import logo from '../assets/images/logo-footer.png';
import europeanUnion from '../assets/images/european-union.png';
import cooperation from '../assets/images/cooperation.png';
import tunisiaFlag from '../assets/images/tunisia-flag.png';
import italienFlag from '../assets/images/italy-flag.png';

import { useTranslation } from 'react-i18next';

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { t } = useTranslation();

  const navigation = [
    { name: t('navbar_accueil'), href: '/' },
    { name: t('navbar_about'), href: 'apropos' },
    { name: t('navbar_event'), href: 'events' },
    { name: t('navbar_news'), href: 'articles' },
    { name: t('navbar_library'), href: 'bibliotheque' },
    { name: t('navbar_contact'), href: 'contact' },
  ];

  let activeStyle = {
    color: '#B7CD00',
  };

  return (
    <div className="bg-white">
      <header className="relative bg-white">
        <div className="relative flex gap-1 lg:h-20 h-14 items-center justify-center px-4 sm:px-6 lg:px-8 z-10 bg-banner">
          <img className="lg:h-14 h-12" src={tunisiaFlag} alt="Tunisia Flag" />
          <img className="lg:h-14 h-12" src={italienFlag} alt="Italy Flag" />
          <img className="lg:h-14 h-12" src={europeanUnion} alt="European Union" />
          <img className="lg:h-14 h-12 lg:w-24 w-20" src={cooperation} alt="Cooperation Italy Tunisia " />
        </div>
        <nav
          aria-label="Top"
          className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"
        >
          <div className="border-b border-gray-200">
            <div className="flex lg:h-24 h-16 items-center">
              <button
                type="button"
                className="rounded-md bg-white p-2 text-gray-700 lg:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Logo */}
              <div className="ml-4 flex lg:ml-0">
                <Link to="/">
                  <span className="sr-only">Eden-Med</span>
                  <img
                    className="lg:h-24 h-14 w-auto"
                    src={logo}
                    alt="Eden-Med"
                  />
                </Link>
              </div>
              <div className="hidden lg:flex lg:flex-1 lg:justify-center lg:gap-x-10">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    style={({ isActive }) =>
                      isActive ? activeStyle : undefined
                    }
                    className="font-semibold text-gray-900 hover:text-edenmedvert transform translate-y-0 hover:translate-y-[2px] duration-500 ease-in-out"
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <div className="ml-auto flex items-center">
                <LanguageSelect />
              </div>
            </div>
          </div>
        </nav>
      </header>
      {/* Mobile Navigation */}
      <Dialog
        as="div"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="relative"
      >
        <Dialog.Panel
          focus="true"
          className="fixed top-[112px] z-20 w-3/4 h-screen overflow-y-auto bg-white pl-7 sm:pl-9 lg:hidden"
        >
          <div className="relative items-center justify-between">
            <div className="absolute top-4 right-4 bg-slate-100 hover:bg-slate-200 rounded-full flex items-center justify-center h-8 w-8">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="mt-2 mr-16 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
}
