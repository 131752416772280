import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';

import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getEvents } from "../actions";

import "../../../App.css";
import Loading from '../../../components/Loading';

const Pagination = (props) => {
    const { t } = useTranslation();
    const language = localStorage.getItem("i18nextLng");
    const months = language === "fr" ? ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"] : ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const [page, setPage] = useState(1);
    const description_max_length = 125;
    const title_max_length = 55;


    useEffect(() => {
        props.getEvents(language, page)
    }, [language, page])

    const handlePageClick = (event) => {
        setPage(event.selected+1)
    };

    const isArabic = (test) => {
        const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
        return pattern.test(test);
    }
    
  return (
    <div id="next">
        <div className="lg:max-w-full h-full mt-10 bg-[#F4F4F2]">
            {props.events.loading ?
                <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 lg:max-w-full h-full mt-10 bg-[#F4F4F2] p-4">
                    <Loading height={"h-[200px]"} />
                    <Loading height={"h-[200px]"} />
                    <Loading height={"h-[200px]"} />
                    <Loading height={"h-[200px]"} />
                </div>
                :
                <>
                    <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 lg:max-w-full h-full mt-10 bg-[#F4F4F2] p-4">
                        {props.events.data.map((item) => (
                            <div key={item._id} className="flex p-2 h-full w-full bg-white rounded-md shadow-md hover:shadow-lg">
                                <div className="w-1/3 h-full rounded-md border border-gray-100 shadow-sm">
                                    <img
                                    src={item.imageUrl} 
                                    alt={item.title}
                                    referrerPolicy="no-referrer"
                                    className="object-fill w-full h-full rounded-md"
                                    />
                                </div>
                                <div className="relative p-4 w-2/3">
                                    <p className="mb-1 sm:text-xs text-[9px] font-semibold tracking-wide uppercase">
                                        <span className="text-edenmedciel">{item.addressGovernorate} - {new Date(item.date).getDate()} {months[new Date(item.date).getMonth()]} {new Date(item.date).getFullYear()}</span>
                                    </p>
                                    <Link
                                        to={`/events/${item._id}`}
                                        className={`inline-block mb-1 sm:h-14 overflow-hidden sm:text-xl text-sm font-bold leading-5 text-edenmedbleu hover:underline ${isArabic(item.title) && "text-right"}`}
                                        dir={isArabic(item.title) ? "rtl" : undefined}
                                    >
                                        {item.title.length > title_max_length ? <span>{item.title.slice(0,title_max_length)}...</span> : <span>{item.title}</span>}
                                    </Link>
                                    <p className={`mb-2 h-20 overflow-hidden text-gray-700 sm:text-base text-xs ${isArabic(item.description) && "text-right"}`} dir={isArabic(item.description) ? "rtl" : undefined}>
                                        {item.description.length > description_max_length ? <span>{item.description.slice(0,description_max_length)}...</span> : <span>{item.description}</span>}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>}
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={4}
                        pageCount={props.events.numberOfPages}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active"
                    />
            
        </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
    events: state.events,
  });
  
const mapActionsToProps = {
    getEvents,
};
export default connect(mapStateToProps, mapActionsToProps)(Pagination);