import { LOADING_CONTACTUS, SUCCESS_CONTACTUS, FAIL_CONTACTUS } from "./types";
import axios from "axios";

export const contactUs = (contactData) => (dispatch) => {
  dispatch({ type: LOADING_CONTACTUS });
  axios
    .post(`${process.env.REACT_APP_API_URL}/messages`, contactData)
    .then((res) => {
      dispatch({
        type: SUCCESS_CONTACTUS,
        payload: "Votre message a été envoyé avec succès",
      });
    })
    .catch((err) =>
      dispatch({
        type: FAIL_CONTACTUS,
        payload: "Une erreur s'est produite veuillez réessayer",
      })
    );
};
