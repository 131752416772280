import { LOADING_BLOG_DETAIL, SUCCESS_GET_BLOG_DETAIL, SET_ERROR_BLOG_DETAIL } from "./types";
import axios from "axios";

export const getBlogDetail = (blogId) => (dispatch) => {
    dispatch({ type: LOADING_BLOG_DETAIL });
    axios
      .get(`${process.env.REACT_APP_API_URL}/blogs/blog/${blogId}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_BLOG_DETAIL,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_BLOG_DETAIL,
          payload: [],
        });
      });
  };