import React from 'react'
import Favorites from './components/Favorites'
import Pagination from './components/Pagination'
import { useTranslation } from "react-i18next";
import EnqueteButton from '../../utils/EnqueteButton';


export default function Articles() {
  const { t } = useTranslation();
  
  return (
    <div className='relative'>
      <EnqueteButton />
      <div className="pt-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8">
        <div className="flex flex-col mb-10 md:flex-row justify-between md:px-0 px-4">
          <h2 className="max-w-md font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
            {t("footer_actualite")}
          </h2>
        </div>
        <div className="bg-[#F4F4F2]">
          <Favorites />
          <Pagination />
        </div>
      </div>
    </div>
  )
}
