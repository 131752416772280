import React from 'react';
import { connect } from 'react-redux';
import { confirmParticipants } from "../eventDetails/actions";
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import EnqueteButton from '../../utils/EnqueteButton';


const EmailConfirmation = (props) => {
  const {activationCode} = useParams()

  useEffect(() => {
    props.confirmParticipants(activationCode)
}, [activationCode])

  return (
    <div className='relative'>
      <EnqueteButton />
      <div className="px-4 mx-auto w-full md:px-24 lg:px-8 lg:py-10 bg-gray-100">
        <p className="text-center my-32 text-3xl font-semibold text-edenmedciel">Votre participation a été envoyeé avec success.</p>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  participants: state.participants
});

const mapActionsToProps = {
  confirmParticipants
};
export default connect(mapStateToProps, mapActionsToProps)(EmailConfirmation);