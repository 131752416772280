import React from 'react'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { sendParticipation } from '../actions';


const validationSchema = yup.object({
  // TODO: add
  name: yup.string().required('Veuillez entrer votre Nom et prénom'),
  phone: yup
    .string()
    .required('Veuillez entrer votre N° de téléphone'),
  email: yup
    .string()
    .email('Veuillez entrer une adresse e-mail valide')
    .required('Veuillez entrer votre adresse e-mail'),
  organism: yup
    .string()
    .required('Veuillez entrer un organisme'),
});

function CTA(props) {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      organism: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      let attendantData = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        organism: values.organism,
        eventId: props.id,
        eventTitle: props.title,
        eventImageUrl: props.imgUrl,
        eventDescription: props.description,
        eventDate: props.date,
      };
      props.sendParticipation(props.id, attendantData);
      resetForm({ values: '' });
    },
  });

  return (
    <div className="px-4 py-16 mx-auto w-full md:px-24 lg:px-8 lg:py-10 bg-gray-100">
        <div className="w-full pb-16">
            <h1 className="text-edenmedvert text-center text-3xl font-semibold uppercase mb-4">participer</h1>
            <h1 className="text-edenmedvert text-center text-xl font-medium">Faites vite! Les places sont limitées.</h1>
            {props.participants.alertMessage && <h1 className="text-red-500 text-center mt-4 text-xl font-medium">{props.participants.alertMessage}</h1>}
            {props.participants.success && <h1 className="text-green-500 text-center mt-4 text-xl font-medium">Consultez votre boite email et confirmez votre participation</h1>}
        </div>
        <div className="flex justify-center">
            <form className="flex flex-col items-center lg:w-1/2 md:w-3/4 w-11/12" onSubmit={formik.handleSubmit}>
              <input
                placeholder="Nom et Prénon"
                required="Veuillez renseigner ce champ"
                type="text"
                className="flex-grow w-full h-12 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              <input
                required
                placeholder="Télephone"
                type="text"
                pattern="\d*"
                className="mt-6 flex-grow w-full h-12 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              <input
                required
                placeholder="Email"
                type="email"
                className="mt-6 flex-grow w-full h-12 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              <input
                required
                placeholder="Organisme"
                type="text"
                className="mt-6 flex-grow w-full h-12 px-4 transition duration-200 bg-white border border-gray-300 rounded shadow-sm appearance-none md:mr-2 md:mb-0 focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
                id="organism"
                name="organism"
                value={formik.values.organism}
                onChange={formik.handleChange}
              />
              <button
                type="submit"
                // disabled={props.product.loading}
                className="disabled:opacity-75 disabled:cursor-no-drop mt-10 w-fit bg-edenmedciel  border hover:border-edenmedciel rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-white hover:text-edenmedciel focus:bg-edenmedciel focus:text-white"
              >
                <span className="">Envoyer</span>
              </button>
            </form>
        </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  participants: state.participants,
});

const mapActionsToProps = { sendParticipation };
export default connect(mapStateToProps, mapActionsToProps)(CTA);
