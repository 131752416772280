import React from 'react';
import Countdown from 'react-countdown';


const Completionist = () => <span className="text-center text-white sm:text-1xl text-lg font-normal uppercase">C'est parti!</span>;

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <>
        <div className="flex flex-col gap-1">
          <p className="text-center text-white sm:text-4xl text-3xl font-semibold">{days.toString()}</p>
          <p className="text-center text-white sm:text-1xl text-lg font-normal uppercase">jours</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-center text-white sm:text-4xl text-3xl font-semibold">{hours.toString()}</p>
          <p className="text-center text-white sm:text-1xl text-lg font-normal uppercase">heures</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-center text-white sm:text-4xl text-3xl font-semibold">{minutes.toString()}</p>
          <p className="text-center text-white sm:text-1xl text-lg font-normal uppercase">minutes</p>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-center text-white sm:text-4xl text-3xl font-semibold">{seconds.toString()}</p>
          <p className="text-center text-white sm:text-1xl text-lg font-normal uppercase">secondes</p>
        </div>
      </>
    );
  }
};

const Hero = ({image, title, date, governorate}) => {
  const days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]


  return (
    <div className="relative w-full flex justify-center">
      <div className="w-full h-screen">
        <img 
          className="sm:object-fill object-cover w-full h-full" 
          src={image} 
          alt="hero" 
          referrerPolicy="no-referrer"/>
      </div>
      <div className="absolute flex flex-col justify-center gap-8 top-1/3 w-fit sm:p-8 p-4 mx-4 rounded-lg bg-black/30 ">
        <p className="text-center text-white sm:text-4xl text-3xl font-semibold uppercase">{title}</p>
        <p className="text-center text-white sm:text-3xl text-2xl font-semibold">{days[new Date(date).getDay()]} le {new Date(date).getDate()} {months[new Date(date).getMonth()]} {new Date(date).getFullYear()} à {governorate}</p>
        <div className="flex flex-row sm:gap-10 gap-6 justify-center">
        <Countdown
          date={new Date(date)}
          renderer={renderer}
        />
        </div>
      </div>
    </div>
  )
}

export default Hero