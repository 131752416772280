import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { list } from '../pages/survey/actions';
import { useTranslation } from "react-i18next";



import SurveyIcon from "../assets/images/survey.png";
import {MdKeyboardArrowDown} from "react-icons/md"
import {FaEnvira} from "react-icons/fa"
import {VscFeedback} from "react-icons/vsc"


const EnqueteButton = (props) => {
  const [active, setActive] = useState(false);
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng")

   useEffect(() => {
    props.list(language)
  }, [language])
  return (
    <div className="fixed right-1 bottom-1 z-20">
      <div className="flex flex-col gap-6 justify-end items-end">
        {active && 
        <div className='flex flex-col sm:w-[400px] w-[98%] h-[500px] bg-gradient-to-b from-slate-900 to-slate-50 rounded-xl p-6 overflow-scroll overflow-y-auto overflow-x-hidden'>
          <div className="flex flex-col p-4">
            <div className="mb-16 flex items-center justify-between gap-1">
              <div className="flex items-center gap-1">
                <FaEnvira className="text-white h-4"/>
                <p className="text-2xl font-bold text-white">EdEn-MED</p>
              </div>
              <div className="flex items-center gap-1">
                <p className="h-10 w-10 rounded-full bg-sky-500 flex justify-center items-center">
                  <VscFeedback className="h-6 w-6 text-white" />
                </p>
                <p className="h-10 w-10 -ml-2 rounded-full bg-edenmedvert flex justify-center items-center">
                  <VscFeedback className="h-6 w-6 text-white" />
                </p>
                <p className="h-10 w-10 -ml-2 rounded-full bg-sky-500 flex justify-center items-center">
                  <VscFeedback className="h-6 w-6 text-white" />
                </p>
              </div>
            </div>
            <div>
              <p className="text-white text-2xl font-bold">
                {t("Enquete_button_salut")}
              </p>
            </div>
            <div>
              <p className="text-white text-2xl font-bold">
                {t("Enquete_button_titre")}
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg p-2">
            <p className="text-lg">
              {t("Enquete_button_texte")}
            </p>
            { props.survey.survey?.length === 0 
            ?
              <p>{t("Enquete_button_null")}</p>
            :
              <div className="flex flex-col gap-2 mt-4">
                {props.survey.survey.map((item)=>(
                  <a key={item._id} href={`${window.location.origin}/#/enquete/${item._id}`}>
                    <p className='bg-gray-100 hover:bg-gray-200 cursor-pointer p-2 rounded-md'>
                      {item.label}
                    </p>
                  </a>
                ))}
              </div>
            }
          </div>
        </div>
        }
        <div 
          className={`w-12 h-12 rounded-full bg-edenmedvert flex items-center justify-center cursor-pointer ${!active && "hover:h-[52px] hover:w-[52px]"}`}
          onClick={()=>{setActive(!active)}}
        >
          {
            active 
            ?
              <MdKeyboardArrowDown className="h-6 w-6 hover:h-8 hover:w-8" />
            :
              <img src={SurveyIcon} alt="survey" className="object-fill h-8 w-8 animate-pulse" />
          }
        </div> 
      </div> 
    </div>
  )
}

const mapStateToProps = (state) => ({
  survey: state.survey,
});

const mapActionsToProps = {
  list
};
export default connect(mapStateToProps, mapActionsToProps)(EnqueteButton);