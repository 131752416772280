import { LOADING_BLOGS, SUCCESS_GET_BLOGS, SET_ERROR_BLOGS, SUCCESS_GET_MOST_SEEN_BLOGS, SUCCESS_GET_ALL_BLOGS, RESET } from "./types";
const initialState = {
  loading: false,
  data: [],
  mostSeenBlogs: [],
  numberOfPages:0,
  totalBlogs:0,
  error: false,
};

export const blogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_BLOGS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_BLOGS:
      return {
        ...state,
        loading: false,
        data: payload.data,
        numberOfPages: payload.numberOfPages,
        error: false,
      };

    case SUCCESS_GET_MOST_SEEN_BLOGS:
      return {
        ...state,
        loading: false,
        mostSeenBlogs: payload.mostSeenBlogs,
        error: false,
      };

      case SUCCESS_GET_ALL_BLOGS:
      return {
        ...state,
        loading: false,
        totalBlogs:payload.totalBlogs,
        error: false,
      };

    case SET_ERROR_BLOGS:
      return {
        ...state,
        loading: false,
        error: true,
        data: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
        mostSeenBlogs: []
      };

    default:
      return state;
  }
}



