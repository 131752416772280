import React from 'react';
import Header from './components/Header';
import ContactInfo from './components/ContactInfo';
import ContactForm from './components/ContactForm';
import EnqueteButton from '../../utils/EnqueteButton';

export default function Index() {
  
  return (
    <div className='relative'>
      <EnqueteButton />
      <Header />
      <ContactInfo />
      <ContactForm />
    </div>
  );
}
