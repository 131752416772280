import { 
        LOADING_EVENT_DETAILS, 
        SUCCESS_GET_EVENT_DETAILS, 
        SET_ERROR_EVENT_DETAILS, 
        LOADING_PARTICIPANTS,
        SUCCESS_GET_PARTICIPANTS,
        SUCCESS_ADD_PARTICIPANTS,
        SET_ERROR_PARTICIPANTS,
        RESET } from "./types";
const initialState = {
  loading: false,
  data: [],
  error: false,
  success: false,
  alertMessage: "",
};

export const eventDetailsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_EVENT_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_EVENT_DETAILS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
      };

    case SET_ERROR_EVENT_DETAILS:
      return {
        ...state,
        loading: false,
        error: true,
        data: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
      };

    default:
      return state;
  }
}


export const participantReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_PARTICIPANTS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_PARTICIPANTS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
        success: false,
        alertMessage:""
      };
    case SUCCESS_ADD_PARTICIPANTS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        alertMessage:""
      };

    case SET_ERROR_PARTICIPANTS:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        alertMessage: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        alertMessage: "",
        data: [],
      };

    default:
      return state;
  }
}



