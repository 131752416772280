import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';


import aboutHero from "../assets/about-home.jpg"


export default function About() {
  const { t } = useTranslation();

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 lg:py-20">
      <div className='relative shadow-2xl rounded-md'>
        <div className="w-full h-[500px] rounded-md">
          <img 
              className="sm:object-fill object-cover w-full h-full rounded-md" 
              src={aboutHero} 
              alt="avatar"
              referrerPolicy="no-referrer" />
        </div>
        <div className={`absolute bg-[#2c2c2bab] rounded-md inset-0`}>
          <div className="flex items-center justify-center h-full">
            <div className="rounded-lg flex flex-col items-center justify-center gap-10 px-4">
              <p className='text-center text-white sm:text-4xl text-3xl font-bold max-w-lg'>{t('home_about_title')}</p>  
              <p className='text-center text-white text-lg font-semibold max-w-2xl'>
                {t('home_about_description')}
              </p> 
              <div className="relative overflow-hidden w-fit py-4 px-12 text-lg bg-edenmedciel hover:bg-white ring-1 hover:ring-edenmedgris">
                <span className="text-gray-600">
                  <Link
                    to="apropos"
                    className="font-semibold text-white hover:text-edenmedciel"
                  >
                    <span className="absolute inset-0" aria-hidden="true" />
                    {t('home_article_btn')}
                  </Link>
                </span>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
