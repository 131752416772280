import { 
        LOADING_EVENTS, 
        SUCCESS_GET_EVENTS, 
        SET_ERROR_EVENTS, 
        SUCCESS_GET_LAST_EVENTS, 
        SUCCESS_GET_ALL_EVENTS,
        RESET } from "./types";
const initialState = {
  loading: false,
  data: [],
  lastEvents: [],
  numberOfPages:0,
  totalEvents:0,
  error: false,
};

export const eventReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_EVENTS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_EVENTS:
      return {
        ...state,
        loading: false,
        data: payload.data,
        numberOfPages: payload.numberOfPages,
        error: false,
      };

    case SUCCESS_GET_ALL_EVENTS:
      return {
        ...state,
        loading: false,
        totalEvents:payload.totalEvents,
        error: false,
      };
      
    case SUCCESS_GET_LAST_EVENTS:
      return {
        ...state,
        loading: false,
        lastEvents: payload.lastEvents,
        error: false,
      };
    case SET_ERROR_EVENTS:
      return {
        ...state,
        loading: false,
        error: true,
        data: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
      };

    default:
      return state;
  }
}



