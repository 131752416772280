import React from 'react';
import Article from './components/Article';
import EnqueteButton from '../../utils/EnqueteButton'


const Index = () => {
  return (
    <div className='relative'>
      <EnqueteButton />
      <Article />
    </div>
  )
}

export default Index