import {
  LOADING_SLIDES,
  SUCCESS_GET_SLIDES,
  LOADING_TESTIMONIALS,
  SUCCESS_GET_TESTIMONIALS,
  LOADING_VISITORS,
  SUCCESS_GET_VISITORS,
  LOADING_PUPUP,
  SUCCESS_GET_PUPUP,
  SET_ERROR,
} from './types';
import axios from 'axios';

export const getSlides = (language) => (dispatch) => {
  dispatch({ type: LOADING_SLIDES });
  axios
    .get(`${process.env.REACT_APP_API_URL}/slider/${language}`)
    .then((res) => {
      dispatch({
        type: SUCCESS_GET_SLIDES,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERROR,
        payload: [],
      });
    });
};

export const getTestimonials = (language) => (dispatch) => {
  dispatch({ type: LOADING_TESTIMONIALS });
  axios
    .get(`${process.env.REACT_APP_API_URL}/testimonials/${language}`)
    .then((res) => {
      dispatch({
        type: SUCCESS_GET_TESTIMONIALS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERROR,
        payload: [],
      });
    });
};

export const getVisitors = () => (dispatch) => {
  dispatch({ type: LOADING_VISITORS });
  axios
    .get(`${process.env.REACT_APP_API_URL}/visitors`)
    .then((res) => {
      dispatch({
        type: SUCCESS_GET_VISITORS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERROR,
        payload: [],
      });
    });
};

export const countVisitors = () => (dispatch) => {
  dispatch({ type: LOADING_VISITORS });
  axios
    .post(`${process.env.REACT_APP_API_URL}/visitors`)
    .then((res) => {
      dispatch(getVisitors());
    })
    .catch((err) => {
      console.log(err.response.data.error);
    });
};

export const getPupup = (language) => (dispatch) => {
  dispatch({ type: LOADING_PUPUP });
  axios
    .get(`${process.env.REACT_APP_API_URL}/pupup/${language}`)
    .then((res) => {
      dispatch({
        type: SUCCESS_GET_PUPUP,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERROR,
        payload: [],
      });
    });
};
