import {
  GET_SURVEY,
  LOADING,
  SET_ERRORS,
  CLEAR_ERRORS,
  SUCCESS_FEEDBACK,
  FAIL_FEEDBACK
} from './types';

const initialState = {
  loading: false,
  survey: [],
  errors: {},
  success: false,
  fail: false,
  alertMessage: "",
};

export const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SURVEY:
      return {
        ...state,
        survey: action.payload,
        loading: false,
      };
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: {},
        open: false,
      };
    case SUCCESS_FEEDBACK:
      return {
        ...state,
        success: true,
        fail: false,
        loading: false,
        alertMessage: action.payload,
      };
    case FAIL_FEEDBACK:
      return {
        ...state,
        fail: true,
        success: false,
        loading: false,
        alertMessage: action.payload,
      };
    default:
      return state;
  }
}
