import { combineReducers } from 'redux';

import contactReducer from '../pages/contact/reducer';
import { homeReducer } from '../pages/home/reducer';
import { faqReducer } from '../pages/faq/reducer';
import { blogReducer } from '../pages/articles/reducer';
import { eventReducer } from '../pages/events/reducer';
import { blogDetailReducer } from '../pages/articleDetails/reducer';
import { aboutReducer } from '../pages/about/reducer';
import { surveyReducer } from '../pages/survey/reducer';
import {
  eventDetailsReducer,
  participantReducer,
} from '../pages/eventDetails/reducer';
import {
  mediasReducer,
  documentsReducer,
  photosReducer,
} from '../pages/library/reducer';

const rootReducer = combineReducers({
  contact: contactReducer,
  faq: faqReducer,
  home: homeReducer,
  about: aboutReducer,
  medias: mediasReducer,
  documents: documentsReducer,
  photos: photosReducer,
  blogs: blogReducer,
  blog: blogDetailReducer,
  events: eventReducer,
  event: eventDetailsReducer,
  participants: participantReducer,
  survey: surveyReducer,
});

export default rootReducer;
