import { LOADING_MEDIAS, 
          SUCCESS_GET_MEDIAS, 
          SET_ERROR_MEDIAS, 
          LOADING_DOCUMENTS, 
          SUCCESS_GET_DOCUMENTS, 
          SET_ERROR_DOCUMENTS, 
          LOADING_PHOTOS,
          SUCCESS_GET_PHOTOS,
          SET_ERROR_PHOTOS,
         } from "./types";
import axios from "axios";

export const getMedias = (language) => (dispatch) => {
    dispatch({ type: LOADING_MEDIAS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/library/medias/${language}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_MEDIAS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_MEDIAS,
          payload: [],
        });
      });
  };


export const getDocuments = (language) => (dispatch) => {
    dispatch({ type: LOADING_DOCUMENTS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/library/documents/${language}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_DOCUMENTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_DOCUMENTS,
          payload: [],
        });
      });
  };


export const getPhotos = (language) => (dispatch) => {
    dispatch({ type: LOADING_PHOTOS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/library/photos/${language}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_PHOTOS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_PHOTOS,
          payload: [],
        });
      });
  };