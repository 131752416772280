import React, { useEffect , useState } from 'react'
import { connect } from 'react-redux';
import { sendFeedback } from '../actions';

const SurveyForm = (props) => {
  const [title, setTitle] = React.useState(props.surveyData.survey.title);
  const [description, setDescription] = React.useState(props.surveyData.survey.description);
  const [questions, SetQuestions] = React.useState(props.surveyData.survey.questions);
  const [formFields, setFormFields] = useState([{question:"", answer:""}])
  const [personalInfoFields, setPersonalInfoFields] = useState([{field:"", value:""}])

  useEffect(()=>{
    setTitle(props.surveyData.survey.title);
    setDescription(props.surveyData.survey.description);
    SetQuestions(props.surveyData.survey.questions);
    if(props.surveyData.survey?.userInformations){
      setPersonalInfoFields(props.surveyData.survey?.userInformations.map((item)=>{
        var rObj ={field:"", value:""};
        rObj["field"] = item;
        return rObj;
      }));
    }
    if(props.surveyData.survey.questions) {
      setFormFields(props.surveyData.survey.questions.map((item)=>{
        var rObj ={question:"", answer:""};
        rObj["question"] = item.body;
        return rObj;
      }));
    }
  },[props.surveyData])

  const handlePersonalInfosFormChange = ( index, event ) => {
    if(personalInfoFields) {
      let data = [...personalInfoFields];
      data[index]["value"] = event.target.value;
      setPersonalInfoFields(data);
    }
  }

  const handleQuestionsFormChange = ( index, event ) => {
    if(formFields) {
      let data = [...formFields];
      data[index]["answer"] = event.target.value;
      setFormFields(data);
    }
  }

  const submit = (e) => {
    e.preventDefault();
    const formData = {
      title: title,
      userInformations: personalInfoFields,
      answers: formFields,
    };
    props.sendFeedback(formData);
    window.location.reload();
    window.scrollTo(0, 0);
  }

  
  return (
    <div>
      <div className="mb-8 pb-8">
          <p className="pb-8 max-w-5xl font-sans text-4xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none">
            { title }
          </p>
          <p className="max-w-3xl font-sans text-lg font-medium">
            { description }
          </p>
      </div>
      <div>
        <form onSubmit={submit} className="border-2 border-edenmedvert md:p-12 p-4 rounded-md">
          <div>
            <div className="mb-8">
              <p className="text-2xl font-semibold text-edenmedvert">Informations personnelles</p>
            </div>
            <div className="flex flex-wrap justify-between pb-8 border-b-[1px] border-b-edenmedvert">
              {props.surveyData.survey.userInformations?.map((field, index) => (
                <div className="mb-6 sm:max-w-[350px] sm:min-w-[300px] w-full" key={index}>
                  <label 
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      {field}
                  </label>
                  <input 
                    required
                    type="text" 
                    name={field}
                    value={personalInfoFields.field}
                    placeholder= {field}
                    onChange={event=>{handlePersonalInfosFormChange(index, event)}}
                    className="block w-full p-2 text-gray-900 border border-edenmedvert sm:text-md focus:ring-1 focus:ring-edenmedvert outline-none" />
                </div>
              ))}
            </div>
          </div>
          <div className="my-8">
            <div className="mb-8">
              <p className="text-2xl font-semibold text-edenmedvert">Formulaire</p>
            </div>
            <div className="sm:px-4 px-0">
              {questions?.map((item, index) => (
                <div key={index}>
                  <p className="sm:text-xl text-lg">{index + 1}. {item.body}</p>
                  <fieldset className="sm:px-[26px] px-5 my-4">
                    <div className="flex items-center mb-4">
                      <input 
                        id={item.answerOptions.first} 
                        type="radio" 
                        name={item.body} 
                        value={item.answerOptions.first} 
                        onChange={event=>{handleQuestionsFormChange(index, event)}}
                        className="w-4 h-4 border-gray-300"
                      />
                      <label htmlFor={item.answerOptions.first} className="block ml-2 sm:text-md text-sm text-gray-900">
                        {item.answerOptions.first}
                      </label>
                    </div>
                    <div className="flex items-center mb-4">
                      <input 
                        id={item.answerOptions.second} 
                        type="radio" 
                        name={item.body} 
                        value={item.answerOptions.second} 
                        onChange={event=>{handleQuestionsFormChange(index, event)}}
                        className="w-4 h-4 border-gray-300"
                      />
                      <label htmlFor={item.answerOptions.second} className="block ml-2 sm:text-md text-sm text-gray-900">
                        {item.answerOptions.second}
                      </label>
                    </div>
                    <div className="flex items-center mb-4">
                      <input 
                        id={item.answerOptions.third} 
                        type="radio" 
                        name={item.body} 
                        value={item.answerOptions.third} 
                        onChange={event=>{handleQuestionsFormChange(index, event)}}
                        className="w-4 h-4 border-gray-300"
                      />
                      <label htmlFor={item.answerOptions.third} className="block ml-2 sm:text-md text-sm text-gray-900">
                        {item.answerOptions.third}
                      </label>
                    </div>
                  </fieldset>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center">
            <button type='submit' className="text-white bg-edenmedciel hover:bg-white hover:text-edenmedciel hover:ring-1 hover:ring-edenmedciel font-medium sm:text-xl text-lg w-fit px-12 py-4 text-center">Envoyer</button>
          </div>
        </form>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => ({
  survey: state.survey,
});

const mapActionsToProps = {
  sendFeedback
};
export default connect(mapStateToProps, mapActionsToProps)(SurveyForm);