import React from 'react'
import cible from "../assets/cible.png"


const Cible = () => {
  return (
    <div className="mt-24">
      <div className="mb-16">
        <h2 className="font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none">
          Public cible & philosophie
        </h2>
      </div>
      <div className="">
        <img
            className="object-fill w-full rounded shadow-lg"
            src={cible}
            alt="zone de couverture"
          />
      </div>
    </div>
  )
}

export default Cible