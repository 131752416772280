import React,{ useEffect } from 'react';
import { connect } from 'react-redux';
import { getMembers } from '../actions';
import Loading from '../../../components/Loading';


const Team = (props) => {

  useEffect(() => {
    props.getMembers();
  }, []);

  return (
    <div className="mt-24">
      <div className="mb-16">
        <h2 className="font-sans text-left text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none">
          Notre équipe
        </h2>
      </div>
      {props.loading 
        ?
          <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4 p-20 bg-gray-100">
              <Loading height={"h-[250px]"} />
              <Loading height={"h-[250px]"} />
              <Loading height={"h-[250px]"} />
              <Loading height={"h-[250px]"} />
          </div>
        :
        <div className="flex flex-wrap gap-10 justify-between p-20 bg-gray-100">
          {props.members.map((item)=>(
              <div key={item._id} className="overflow-hidden w-[230px] mx-auto">
                  <div className="aspect-1">
                      <img
                      src={item.imageUrl}
                      className="object-cover w-full aspect-1"
                      alt={item.fullname}
                      />
                  </div>
                  <div className="py-2">
                  <p className="mb-1 text-center font-semibold tracking-wide uppercase">
                      {item.fullname}
                  </p>
                  <p className="text-center text-gray-700">
                      {item.occupation}
                  </p>
                  </div>
              </div>
          ))}
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  members: state.about.members,
  loading: state.about.loading,
});

const mapActionsToProps = {
  getMembers,
};
export default connect(mapStateToProps, mapActionsToProps)(Team);