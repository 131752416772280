import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { getFaqs } from "../../faq/actions";
import Loading from '../../../components/Loading';


const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  

  return (
    <div className="border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex gap-2 items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-md text-left font-medium w-11/12">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? 'transform rotate-180' : ''
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-gray-700">{children}</p>
        </div>
      )}
    </div>
  );
};

const Faq = (props) => {
  const { t } = useTranslation();
  const language = localStorage.getItem("i18nextLng")

  useEffect(() => {
    props.getFaqs(language)
  }, [language])

  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8 lg:py-20">
      <div className="flex flex-col mb-6 md:flex-row md:mb-10 justify-between">
        <h2 className="max-w-md mb-6 font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
        {t("home_faq_title")}
        </h2>
        <div className="hidden md:block">
          <div className="relative overflow-hidden rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel hover:bg-white ring-1 hover:ring-edenmedgris">
            <span className="text-gray-600">
              <Link to="/faq" className="font-semibold text-white hover:text-edenmedciel">
                <span className="absolute inset-0" aria-hidden="true" />
                {t("home_article_btn")} <span aria-hidden="true">&rarr;</span>
              </Link>
            </span>
          </div>
        </div>
      </div>
      {/*  */}
      {props.faq.loading 
        ?
          <div className="space-y-4">
              <Loading height={"h-[100px]"} />
              <Loading height={"h-[100px]"} />
              <Loading height={"h-[100px]"} />
              <Loading height={"h-[100px]"} />
              <Loading height={"h-[100px]"} />
          </div>
        :
          <div className="space-y-4">
            {props.faq.data.map((item)=>(
              <Item title={item.question} key={item._id}>
                {item.response}
              </Item>
            ))}
          </div>
        }
      <div className="md:hidden flex mt-12 justify-center">
          <div className="relative overflow-hidden w-fit rounded-full py-1.5 px-4 text-sm leading-6 bg-edenmedciel hover:bg-white ring-1 hover:ring-edenmedgris">
            <span className="text-gray-600">
              <Link to="/faq" className="font-semibold text-white hover:text-edenmedciel">
                <span className="absolute inset-0" aria-hidden="true" />
                {t("home_article_btn")} <span aria-hidden="true">&rarr;</span>
              </Link>
            </span>
          </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  faq: state.faq,
});

const mapActionsToProps = {
  getFaqs,
};
export default connect(mapStateToProps, mapActionsToProps)(Faq);
