import React from 'react'
import EnqueteButton from '../../utils/EnqueteButton'
import Approche from './components/Approche'
import Cible from './components/Cible'
import Couverture from './components/Couverture'
import Hero from './components/Hero'
import Main from './components/Main'
import Partenaires from './components/Partenaires'
import { useTranslation } from "react-i18next";

import Team from './components/Team'

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <EnqueteButton />
      <div className="pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-8 lg:px-8">
        <div className="flex flex-col mb-10 md:flex-row justify-between md:px-0 px-4">
          <h2 className="max-w-md font-sans text-4xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none xl:max-w-lg">
            {t("Apropos_EdEn_MED")}
          </h2>
        </div>
        <Hero />
        <div className="md:px-0 px-4">
          <Main />
          <Couverture />
          <Cible />
          <Approche />
          <Partenaires />
          <Team />
        </div>
      </div>
    </div>
  )
}

export default About