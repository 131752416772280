import { 
        LOADING_MEMBERS, 
        SUCCESS_GET_MEMBERS, 
        SET_ERROR, 
       } from "./types";
import axios from "axios";
  
  export const getMembers = () => (dispatch) => {
    dispatch({ type: LOADING_MEMBERS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/members`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_MEMBERS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR,
          payload: [],
        });
      });
  };