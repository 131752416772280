import { LOADING_FAQS, SUCCESS_GET_FAQS, SET_ERROR_FAQS, RESET } from "./types";
const initialState = {
  loading: false,
  data: [],
  error: false,
};

export const faqReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_FAQS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_FAQS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
      };

    case SET_ERROR_FAQS:
      return {
        ...state,
        loading: false,
        error: true,
        data: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
      };

    default:
      return state;
  }
}



