import React from 'react';
import { GiArrowDunk } from "react-icons/gi";
import zone from "../assets/demo-zone.png";

const Couverture = () => {
  return (
    <div className="mt-24">
      <div className="mb-16">
        <h2 className="font-sans text-3xl font-bold tracking-tight text-edenmedvert sm:text-4xl sm:leading-none">
          Zone de couverture du programme
        </h2>
      </div>
      <div className="grid gap-16 lg:grid-cols-2">
        <div className="flex flex-col pt-8">
          <p className="pb-4">
            Le programme Italie-Tunisie 2014-2020 fait partiede la coopération transfrontalière (CT) de l'Union 
            Européenne dans le cadre de son Instrument Européen de Voisinage (IEV)
          </p>
          <p className="flex justify-center items-center h-24"><GiArrowDunk className="w-24 h-24 text-edenmedciel" /></p>
          <p className="text-edenmedciel py-4">
            Soutenir le développement de PME et l'entreprenariat, <strong>l'éducation</strong>, la recherche, le développement scientifique
            et <strong>l'innovation</strong>, ainsi que <strong>la protection de l'environnement</strong>
          </p>
          <h2 className="text-lg font-semibold text-orange-500">Zone d'éligibilité</h2>
          <p>
            Résident dans la zone : environ 16 millions d'habitants
          </p>
          <p>
            Extension territoriale : environ 200 000 kilomètres carrés
          </p>
          <h2 className="text-lg font-semibold text-orange-500 pt-4">Pays participants et régions éligibles</h2>
          <p className="w-2/3 pb-4">
            Les territoires situés de part et d'autre de la route maritime qui borde la Scilie de la Tunisie
          </p>
          <p>
            <span className="text-edenmedciel text-lg font-semibold">Pour l'Italie:</span> Scilie ( 8 zones provinciales )
          </p>
          <p>
          <span className="text-edenmedciel text-lg font-semibold">Pour la Tunisie:</span> 15 governorats
          </p>
        </div>
        <div className="flex justify-center max-w-xl">
          <img
            className="object-fill w-full rounded shadow-lg"
            src={zone}
            alt="zone de couverture"
          />
        </div>
      </div>
    </div>
  )
}

export default Couverture