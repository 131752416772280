import { 
        LOADING_EVENTS, 
        SUCCESS_GET_EVENTS, 
        SET_ERROR_EVENTS, 
        SUCCESS_GET_LAST_EVENTS, 
        SUCCESS_GET_ALL_EVENTS,
       } from "./types";
import axios from "axios";

export const getEvents = (language, page) => (dispatch) => {
    dispatch({ type: LOADING_EVENTS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/events/${language}?page=${page}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_EVENTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_EVENTS,
          payload: [],
        });
      });
  };

export const getLastEvents = (language) => (dispatch) => {
    dispatch({ type: LOADING_EVENTS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/events/lastevent/${language}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_LAST_EVENTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_EVENTS,
          payload: [],
        });
      });
  };


export const getAllEvents = () => (dispatch) => {
    dispatch({ type: LOADING_EVENTS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/events`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_ALL_EVENTS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_EVENTS,
          payload: [],
        });
      });
  };
