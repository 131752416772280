import { LOADING_BLOG_DETAIL, SUCCESS_GET_BLOG_DETAIL, SET_ERROR_BLOG_DETAIL, RESET } from "./types";
const initialState = {
  loading: false,
  data: [],
  error: false,
};

export const blogDetailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_BLOG_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_BLOG_DETAIL:
      return {
        ...state,
        loading: false,
        data: payload,
        error: false,
      };

    case SET_ERROR_BLOG_DETAIL:
      return {
        ...state,
        loading: false,
        error: true,
        data: payload,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
      };

    default:
      return state;
  }
}



