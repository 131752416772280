import { LOADING_FAQS, SUCCESS_GET_FAQS, SET_ERROR_FAQS } from "./types";
import axios from "axios";

export const getFaqs = (language) => (dispatch) => {
    dispatch({ type: LOADING_FAQS });
    axios
      .get(`${process.env.REACT_APP_API_URL}/faqs/${language}`)
      .then((res) => {
        dispatch({
          type: SUCCESS_GET_FAQS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ERROR_FAQS,
          payload: [],
        });
      });
  };