import {
  LOADING_SLIDES,
  SUCCESS_GET_SLIDES,
  LOADING_TESTIMONIALS,
  SUCCESS_GET_TESTIMONIALS,
  LOADING_VISITORS,
  SUCCESS_ADD_VISITORS,
  SUCCESS_GET_VISITORS,
  LOADING_PUPUP,
  SUCCESS_GET_PUPUP,
  SET_ERROR,
  RESET,
} from './types';
const initialState = {
  loadingSlides: false,
  slides: [],
  slideAutoplayDelay: 2500,

  loadingTestimonials: false,
  testimonials: [],

  loadingVisitors: false,
  visitors: [],
  loadingPupup: false,
  pupup: {},
  error: false,
};

export const homeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOADING_SLIDES:
      return {
        ...state,
        loadingSlides: true,
      };
    case SUCCESS_GET_SLIDES:
      return {
        ...state,
        loadingSlides: false,
        slides: payload,
        slideAutoplayDelay: payload[0].slideAutoplayDelay,
        error: false,
      };
    case LOADING_TESTIMONIALS:
      return {
        ...state,
        loadingTestimonials: true,
      };
    case SUCCESS_GET_TESTIMONIALS:
      return {
        ...state,
        loadingTestimonials: false,
        testimonials: payload,
        error: false,
      };
    case LOADING_VISITORS:
      return {
        ...state,
        loadingVisitors: true,
      };
    case SUCCESS_ADD_VISITORS:
      return {
        ...state,
        loadingVisitors: false,
        visitors: payload,
        error: false,
      };
    case SUCCESS_GET_VISITORS:
      return {
        ...state,
        loadingVisitors: false,
        visitors: payload,
        error: false,
      };
    case LOADING_PUPUP:
      return {
        ...state,
        loadingPupup: true,
      };
    case SUCCESS_GET_PUPUP:
      return {
        ...state,
        loadingPupup: false,
        pupup: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        loadingSlides: false,
        loadingTestimonials: false,
        error: true,
      };

    case RESET:
      return {
        ...state,
        loadingTestimonials: false,
        error: false,
        testimonials: [],
      };

    default:
      return state;
  }
};
