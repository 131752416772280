import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import franceFlag from '../assets/images/france-flag.png';
import italyFlag from '../assets/images/italy-flag.png';

const languages = {
  fr: { label: 'Français', flag: franceFlag, alt: 'FR', active: true },
  it: { label: 'Italiano', flag: italyFlag, alt: 'IT', active: false },
};

const LanguageSelect = () => {
  const selected = localStorage.getItem('i18nextLng') || 'fr';
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languages[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <div className="lg:ml-8 flex w-32">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
            onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
          >
            <div className="flex items-center text-gray-700 hover:text-gray-800">
              <img
                src={languages[selected].flag}
                alt={languages[selected].alt}
                className="block h-auto w-5 flex-shrink-0"
              />
              <span className="ml-3 block text-sm font-medium">
                {languages[selected].label}
              </span>
              <span className="sr-only">, change language</span>
            </div>

            <ChevronDownIcon
              className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {Object.keys(languages)?.map((option) => (
                <Menu.Item key={option}>
                  {({ active }) => (
                          <button
                          onClick={() => {
                            i18next.changeLanguage(option);
                              }}
                              className="px-4 py-2 text-sm flex items-center w-full font-medium text-gray-900">
                      <img
                        src={languages[option].flag}
                        alt={languages[option].alt}
                        className="block h-auto w-5 flex-shrink-0"
                      />
                      <span className="ml-4">{languages[option].label}</span>
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default LanguageSelect;
